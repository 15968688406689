import axios from 'axios';
import getEnv from '@clearscore-group/lib.helpers.envs';
import { getMarket } from '@clearscore-group/lib.helpers.market';
import { orchestrateAnonymousCookie } from '@clearscore-group/lib.helpers.cookies';

import { getSanitisedUrl } from './helpers';

const getBaseUrl = (clientUrl) => clientUrl.replace(/@market@/gi, getMarket?.() || 'global');

export const sendToTrackingEventReceiver = async ({ name, event, uuid, props, app }) => {
    const { url, search } = getSanitisedUrl();
    const additionalProps = event === 'page' ? { path: window.location.pathname, ...props } : props;

    const clientUrl = getEnv('INTERNATIONAL_API_DOMAIN') ?? '';

    const payload = {
        ...(event === 'track' && { event: name }),
        timestamp: new Date().toISOString(),
        userId: uuid,
        anonymousId: orchestrateAnonymousCookie(),
        type: event,
        [event === 'identify' ? 'traits' : 'properties']: additionalProps,
        context: {
            page: {
                path: window.location.pathname,
                referrer: document.referrer || 'Direct',
                search,
                title: document.title,
                url,
            },
            userAgent: window.navigator.userAgent,
            locale: window.navigator.languages?.[0] ? window.navigator.languages[0] : window.navigator.language,
        },
    };

    await axios
        .post('te-receiver/publish', payload, {
            baseURL: getBaseUrl(clientUrl),
            headers: { 'x-cs-site': props.cs_site, 'x-cs-app': app || getEnv('APP') },
        })
        .catch(() => {});
};

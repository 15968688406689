export const PROFILE_FETCH = 'CS/PROFILE_FETCH';
export const PROFILE_FETCH_SUCCESS = `CS/PROFILE_FETCH_SUCCESS`;
export const PROFILE_FETCH_ERROR = `CS/PROFILE_FETCH_ERROR`;

export const PROFILE_SAVE = 'CS/PROFILE_SAVE';
export const PROFILE_SAVE_SUCCESS = `CS/PROFILE_SAVE_SUCCESS`;
export const PROFILE_SAVE_ERROR = `CS/PROFILE_SAVE_ERROR`;

export const PROFILE_REMOVE = 'CS/PROFILE_REMOVE';
export const PROFILE_REMOVE_SUCCESS = `CS/PROFILE_REMOVE_SUCCESS`;
export const PROFILE_REMOVE_ERROR = `CS/PROFILE_REMOVE_ERROR`;

export const PROFILE_ERASE = 'CS/PROFILE_ERASE';
export const PROFILE_ERASE_SUCCESS = `CS/PROFILE_ERASE_SUCCESS`;
export const PROFILE_ERASE_ERROR = `CS/PROFILE_ERASE_ERROR`;

export const PROFILE_FETCH_REPORT_ADDRESSES = 'CS/PROFILE_FETCH_REPORT_ADDRESSES';
export const PROFILE_FETCH_REPORT_ADDRESSES_SUCCESS = `CS/PROFILE_FETCH_REPORT_ADDRESSES_SUCCESS`;
export const PROFILE_FETCH_REPORT_ADDRESSES_ERROR = `CS/PROFILE_FETCH_REPORT_ADDRESSES_ERROR`;

export const PROFILE_SAVE_CURRENT_ADDRESS = 'CS/PROFILE_SAVE_CURRENT_ADDRESS';
export const PROFILE_SAVE_CURRENT_ADDRESS_SUCCESS = `CS/PROFILE_SAVE_CURRENT_ADDRESS_SUCCESS`;
export const PROFILE_SAVE_CURRENT_ADDRESS_ERROR = `CS/PROFILE_SAVE_CURRENT_ADDRESS_ERROR`;

export const PROFILE_SEND_USER_DATA = 'CS/PROFILE_SEND_USER_DATA';
export const PROFILE_SEND_USER_DATA_SUCCESS = `CS/PROFILE_SEND_USER_DATA_SUCCESS`;
export const PROFILE_SEND_USER_DATA_ERROR = `CS/PROFILE_SEND_USER_DATA_ERROR`;

export const PROFILE_SEND_USER_DATA_UNVERIFIED_EMAIL = 'CS/PROFILE_SEND_USER_DATA_UNVERIFIED_EMAIL';

export const PROFILE_RESEND_EMAIL_VERIFICATION = 'CS/PROFILE_RESEND_EMAIL_VERIFICATION';
export const PROFILE_RESEND_EMAIL_VERIFICATION_SUCCESS = `CS/PROFILE_RESEND_EMAIL_VERIFICATION_SUCCESS`;
export const PROFILE_RESEND_EMAIL_VERIFICATION_ERROR = `CS/PROFILE_RESEND_EMAIL_VERIFICATION_ERROR`;

export const PROFILE_MKTG_PREF_FETCH = 'CS/PROFILE_MKTG_PREF_FETCH';
export const PROFILE_MKTG_PREF_FETCH_SUCCESS = `CS/PROFILE_MKTG_PREF_FETCH_SUCCESS`;
export const PROFILE_MKTG_PREF_FETCH_ERROR = `CS/PROFILE_MKTG_PREF_FETCH_ERROR`;

export const PROFILE_MKTG_PREF_SAVE = 'CS/PROFILE_MKTG_PREF_SAVE';
export const PROFILE_MKTG_PREF_SAVE_SUCCESS = `CS/PROFILE_MKTG_PREF_SAVE_SUCCESS`;
export const PROFILE_MKTG_PREF_SAVE_ERROR = `CS/PROFILE_MKTG_PREF_SAVE_ERROR`;

export const PROFILE_SAVE_TERMS = 'CS/PROFILE_SAVE_TERMS';
export const PROFILE_SAVE_TERMS_SUCCESS = `CS/PROFILE_SAVE_TERMS_SUCCESS`;
export const PROFILE_SAVE_TERMS_ERROR = `CS/PROFILE_SAVE_TERMS_ERROR`;

import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getBankName = (state) => resolveProp('profile.data.bankName', state) || null;
const getAnnualIncome = (state) => resolveProp('profile.data.annualIncome', state) || {};
const getIncome = (state) => resolveProp('profile.data.income', state) || {};

export default createSelector(getBankName, getAnnualIncome, getIncome, (bankName, annualIncome, income) => ({
    bankName,
    annualIncome,
    income,
}));

const blacklistedSearchParams = ['email', 'firstName', 'lastName', 'dob'];

/* eslint-disable import/prefer-default-export */
export const getSanitisedUrl = () => {
    const { location } = window;
    const url = new URL(location.href);
    blacklistedSearchParams.forEach((p) => url.searchParams.delete(p));
    const search = !url.search && location.search ? '?' : url.search;
    const urlString = `${url.protocol}//${url.host}${url.pathname}${search}`;

    return { url: urlString, search };
};

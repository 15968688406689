import * as sessionTypes from '@clearscore-group/lib.constants.session-action-types';

/** Type for the bridge */
const ROUTER_TYPE = ['@@router'];
const DEFAULT_TYPES = [sessionTypes.LOGIN_OAUTH_REFRESH_SUCCESS];

/**
 * Conditional to check if action should be pushed
 * Currently pushing all router and session stuff
 * @todo: if there is anymore we should come up with a better pattern
 * @param {object} action redux action
 * @param {Array} appBridgeTypes array of type to bridge
 * @returns {boolean} if action structure is correct
 */
function _shouldBridge(action, appBridgeTypes) {
    if (!action) throw Error('action-bridge-types: undefined action');
    if (!action.type) throw Error('action-bridge-types: undefined action.type');
    const types = [...DEFAULT_TYPES, ...appBridgeTypes];
    return action.type.includes(ROUTER_TYPE) || types.includes(action.type);
}

/**
 * Propagate all actions to core store if available
 * @returns {Function} middleware
 */
const _actionBridge = (store, next, action, coreDispatch) => {
    if (coreDispatch) coreDispatch(action);
    next(action);
};

/**
 * If action has correct signature, perform API request.
 * Dispatch success or error actions
 * @param {object} coreDispatch redux store
 * @param {array} appBridgeTypes array of type to bridge
 * @returns {Function} middleware
 */
const actionBridgeMiddleware =
    (coreDispatch, appBridgeTypes = []) =>
    (store) =>
    (next) =>
    (action) =>
        _shouldBridge(action, appBridgeTypes) ? _actionBridge(store, next, action, coreDispatch) : next(action);

export default actionBridgeMiddleware;

/* eslint-disable camelcase */
import { getSessionCookie } from '@clearscore-group/lib.helpers.cookies';
import { isValidLocale } from '@clearscore-group/lib.helpers.i18n';

export interface CSSessionCookie {
    access_token: string;
    refresh_token: string;
    session_id: string;
    o_token: string;
    market: string;
    locale: string;
}

interface CustomDetectorOptions {
    name: string;
    lookup: () => string | undefined;
}

const sessionCookie: CustomDetectorOptions = {
    name: 'sessionCookie',
    lookup: () => {
        try {
            const sessionCookieObj: CSSessionCookie = getSessionCookie();
            return isValidLocale(sessionCookieObj.locale) ? sessionCookieObj.locale : undefined;
        } catch {
            return undefined;
        }
    },
};

export default sessionCookie;

import getUid from '@clearscore-group/lib.helpers.uid';

import { getCookie, setCookie, removeCookie } from './cookies';
import { CS_ANONYMOUS_ID_COOKIE } from './constants';

const ONE_YEAR = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

const setAnonymousCookie = (anonymousId) => {
    setCookie(CS_ANONYMOUS_ID_COOKIE, anonymousId, {
        expires: ONE_YEAR,
    });
    setCookie('ajs_anonymous_id', anonymousId, {
        expires: ONE_YEAR,
    });
};

/*
This function is both a getter and a setter, the reason for this is because we have
users may have an ajs_anonymous_id cookie already set. We want to use this if they do
(and set CS_ANONYMOUS_ID to this value) and set it if they don't just in case they ever do
load Segment (browser change etc). These cookies should ALWAYS match and we should try
and get the ajs_anonymous_id cookie first, then CS_ANONYMOUS_ID, then generate and set
both to that value, the order matters.

At the time of writing, we are using Segment library on the website/webapp and also
tracking via `tracking-event-receiver`.
*/
export const orchestrateAnonymousCookie = () => {
    const segmentAnonId = getCookie('ajs_anonymous_id');
    const csAnonId = getCookie(CS_ANONYMOUS_ID_COOKIE);

    // If both cookies are undefined or they don't match we need to refresh the cookies to make the match
    const shouldRefreshAnonymousId = (!csAnonId && !segmentAnonId) || csAnonId !== segmentAnonId;

    if (shouldRefreshAnonymousId) {
        const uid = segmentAnonId || csAnonId || getUid();

        setAnonymousCookie(uid);

        return uid;
    }

    // We want to keep the TTL on the cookies at 1 year
    setAnonymousCookie(csAnonId);

    return csAnonId;
};

export const removeAnonymousCookie = () => removeCookie(CS_ANONYMOUS_ID_COOKIE);

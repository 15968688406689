import * as requestStatuses from '@clearscore-group/lib.constants.request-statuses';

import {
    IDENTITY_IDENTIFY,
    IDENTITY_IDENTIFY_SUCCESS,
    IDENTITY_IDENTIFY_ERROR,
    IDENTITY_REIDENTIFY,
    IDENTITY_REIDENTIFY_SUCCESS,
    IDENTITY_REIDENTIFY_ERROR,
    IDENTITY_RESTART,
    IDENTITY_RESTART_SUCCESS,
    IDENTITY_RESTART_ERROR,
    IDENTITY_GENERATE_TOKEN,
    IDENTITY_GENERATE_TOKEN_SUCCESS,
    IDENTITY_GENERATE_TOKEN_ERROR,
    IDENTITY_VERIFY_OTP_TOKEN,
    IDENTITY_VERIFY_OTP_TOKEN_SUCCESS,
    IDENTITY_VERIFY_OTP_TOKEN_ERROR,
    IDENTITY_CHOOSE,
    IDENTITY_CHOOSE_SUCCESS,
    IDENTITY_CHOOSE_ERROR,
    IDENTITY_FETCH_KBA,
    IDENTITY_VERIFY_KBA,
    IDENTITY_FETCH_KBA_SUCCESS,
    IDENTITY_VERIFY_KBA_SUCCESS,
    IDENTITY_FETCH_KBA_ERROR,
    IDENTITY_VERIFY_KBA_ERROR,
    IDENTITY_VERIFY_BAV,
    IDENTITY_VERIFY_BAV_SUCCESS,
    IDENTITY_VERIFY_BAV_ERROR,
    IDENTITY_SKIP_BAV,
    IDENTITY_SKIP_BAV_SUCCESS,
    IDENTITY_SKIP_BAV_ERROR,
    IDENTITY_SKIP_OTP,
    IDENTITY_SKIP_OTP_SUCCESS,
    IDENTITY_SKIP_OTP_ERROR,
    IDENTITY_SKIP_REIDENTIFY,
    IDENTITY_SKIP_REIDENTIFY_SUCCESS,
    IDENTITY_SKIP_REIDENTIFY_ERROR,
} from './identity.types';

const initialState = {
    fetchStatus: requestStatuses.INITIAL,
    chooseStatus: requestStatuses.INITIAL,
    verifyStatus: requestStatuses.INITIAL,
    otpVerifyStatus: requestStatuses.INITIAL,
    bavStatus: requestStatuses.INITIAL,
    reidentifyStatus: requestStatuses.INITIAL,
    otpSkipStatus: requestStatuses.INITIAL,
    skipReidentifyStatus: requestStatuses.INITIAL,
};

export const domain = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case IDENTITY_IDENTIFY:
        case IDENTITY_GENERATE_TOKEN:
        case IDENTITY_FETCH_KBA:
            return { ...state, fetchStatus: requestStatuses.PENDING };
        case IDENTITY_VERIFY_OTP_TOKEN:
            return { ...state, otpVerifyStatus: requestStatuses.PENDING };
        case IDENTITY_VERIFY_KBA:
            return { ...state, verifyStatus: requestStatuses.PENDING };
        case IDENTITY_IDENTIFY_SUCCESS:
        case IDENTITY_FETCH_KBA_SUCCESS:
            return { ...state, ...payload, fetchStatus: requestStatuses.SUCCESS };
        case IDENTITY_VERIFY_OTP_TOKEN_SUCCESS:
            return {
                ...state,
                accountIDVStatus: payload.accountIDVStatus,
                otpVerifyStatus: requestStatuses.SUCCESS,
            };
        case IDENTITY_VERIFY_KBA_SUCCESS:
            return { ...state, ...payload, verifyStatus: requestStatuses.SUCCESS };
        case IDENTITY_GENERATE_TOKEN_SUCCESS:
            return {
                ...state,
                email: payload.email,
                mobileNumber: payload.mobileNumber,
                fetchStatus: requestStatuses.SUCCESS,
            };
        case IDENTITY_GENERATE_TOKEN_ERROR:
            if (payload.status === 400) {
                return {
                    ...state,
                    email: payload.data.email,
                    mobileNumber: payload.data.mobileNumber,
                    fetchStatus: requestStatuses.SUCCESS,
                };
            }
            return { ...state, fetchStatus: requestStatuses.ERROR };
        case IDENTITY_VERIFY_OTP_TOKEN_ERROR:
            return { ...state, otpVerifyStatus: requestStatuses.ERROR };
        case IDENTITY_IDENTIFY_ERROR:
        case IDENTITY_FETCH_KBA_ERROR:
            return { ...state, fetchStatus: requestStatuses.ERROR };
        case IDENTITY_VERIFY_KBA_ERROR:
            return { ...state, verifyStatus: requestStatuses.ERROR };
        case IDENTITY_CHOOSE:
            return { ...state, chooseStatus: requestStatuses.PENDING };
        case IDENTITY_CHOOSE_SUCCESS:
            return { ...state, chooseStatus: requestStatuses.SUCCESS };
        case IDENTITY_CHOOSE_ERROR:
            return { ...state, chooseStatus: requestStatuses.ERROR };
        case IDENTITY_VERIFY_BAV:
        case IDENTITY_SKIP_BAV:
            return { ...state, bavStatus: requestStatuses.PENDING };
        case IDENTITY_SKIP_OTP:
            return { ...state, otpSkipStatus: requestStatuses.PENDING };
        case IDENTITY_VERIFY_BAV_SUCCESS:
        case IDENTITY_SKIP_BAV_SUCCESS:
            return { ...state, bavStatus: requestStatuses.SUCCESS };
        case IDENTITY_SKIP_OTP_SUCCESS:
            return { ...state, otpSkipStatus: requestStatuses.SUCCESS };
        case IDENTITY_VERIFY_BAV_ERROR:
        case IDENTITY_SKIP_BAV_ERROR:
            return { ...state, bavStatus: requestStatuses.ERROR };
        case IDENTITY_SKIP_OTP_ERROR:
            return { ...state, otpSkipStatus: requestStatuses.ERROR };
        case IDENTITY_REIDENTIFY:
            return { ...state, reidentifyStatus: requestStatuses.PENDING };
        case IDENTITY_REIDENTIFY_SUCCESS:
            return { ...state, ...payload, reidentifyStatus: requestStatuses.SUCCESS };
        case IDENTITY_REIDENTIFY_ERROR:
            return { ...state, reidentifyStatus: requestStatuses.ERROR };
        case IDENTITY_RESTART:
            return { ...state, restartStatus: requestStatuses.PENDING };
        case IDENTITY_RESTART_SUCCESS:
            return { ...state, restartStatus: requestStatuses.SUCCESS };
        case IDENTITY_RESTART_ERROR:
            return { ...state, restartStatus: requestStatuses.ERROR };
        case IDENTITY_SKIP_REIDENTIFY:
            return { ...state, skipReidentifyStatus: requestStatuses.PENDING };
        case IDENTITY_SKIP_REIDENTIFY_SUCCESS:
            return { ...state, skipReidentifyStatus: requestStatuses.SUCCESS };
        case IDENTITY_SKIP_REIDENTIFY_ERROR:
            return { ...state, skipReidentifyStatus: requestStatuses.ERROR };
        default:
            return state;
    }
};

export default domain;

/* eslint-disable prefer-spread */
/* eslint-disable prefer-template */
// must be ie11 compatible
import * as logger from 'redux-logger';
import type * as Redux from 'redux';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import { compose, applyMiddleware } from 'redux';

const regex = /@@redux-form\/(BLUR|FOCUS|CHANGE)/g;

function logging(name?: string): Redux.Middleware {
    // redux-logger has issue for this https://github.com/LogRocket/redux-logger/issues/233
    // rollup and webpack seem to understand it's build in different way, so need to check,
    // which one is fine
    // @ts-expect-error ts-middleware-issue
    const createLogger: typeof logger.createLogger = logger.__moduleExports
        ? // @ts-expect-error ts-middleware-issue
          logger.__moduleExports.createLogger
        : logger.createLogger;
    return createLogger({
        collapsed: function collapsed(getState, action, logEntry) {
            return !logEntry?.error;
        },
        titleFormatter: function titleFormatter(action, time, took) {
            return '[' + name + '] ' + action.type + ' (' + took.toFixed(2) + 'ms)';
        },
        predicate: function predicate(getState, action) {
            return !action.type.match(regex);
        },
    });
}

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeWithDevTools =
    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
    canUseDom() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          function () {
              if (arguments.length === 0) return undefined;
              // eslint-disable-next-line prefer-rest-params
              if (typeof arguments[0] === 'object') return compose;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return compose.apply(null, arguments); // eslint-disable-line prefer-rest-params, @typescript-eslint/ban-ts-comment, max-len
          };

function composeWithTools(options: {
    middleWares: Redux.Middleware[];
    name?: string;
}): Redux.StoreEnhancer<{ dispatch: unknown }, unknown> {
    if (process.env.DEBUG) {
        options.middleWares.unshift(logging(options.name));
        // @ts-expect-error ts-middleware-issue
        return composeWithDevTools({ name: options.name })(applyMiddleware.apply(null, options.middleWares));
    }
    return compose(applyMiddleware.apply(null, options.middleWares));
}

export default composeWithTools;

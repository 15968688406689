/* eslint-disable import/prefer-default-export */
import getEnv from '@clearscore-group/lib.helpers.envs';

/**
 * Get the app version from querystring if running in webview
 * context (to be passed eventually to segmenter)
 */
export const getWebviewAppVersion = () => {
    if (!getEnv('WEBVIEW')) return null;

    return new URLSearchParams(window.location?.search).get('appVersion');
};

import { GB, ZA, GLOBAL, MARKETS, EN_GB, EN_ZA, LOCALES } from '@clearscore-group/lib.config.i18n';

import {
    setActiveLocale,
    setActiveMarket,
    setMarketsEnvs,
    setLocales,
    setMarkets,
    fetchMarketConfig,
    setFetchPending,
    fetchStaticMarketConfig,
    fetchSduiMarketConfig,
} from './actions';
import {
    MARKET_SET_ACTIVE_LOCALE,
    MARKET_SET_ACTIVE_MARKET,
    MARKET_SET_LOCALES,
    MARKET_SET_MARKETS,
    MARKET_SET_ENVS,
    MARKET_FETCH_CONFIG,
    MARKET_FETCH_CONFIG_PENDING,
    MARKET_FETCH_CONFIG_ERROR,
    MARKET_FETCH_CONFIG_SUCCESS,
    MARKET_FETCH_STATIC_CONFIG,
    MARKET_FETCH_STATIC_CONFIG_ERROR,
    MARKET_FETCH_STATIC_CONFIG_SUCCESS,
    MARKET_FETCH_SDUI_CONFIG,
    MARKET_FETCH_SDUI_CONFIG_ERROR,
    MARKET_FETCH_SDUI_CONFIG_PENDING,
    MARKET_FETCH_SDUI_CONFIG_SUCCESS,
} from './types';
import { domain } from './reducers';
import {
    getPredicate,
    getFetchStatus,
    getConfig,
    getActiveLocale,
    getActiveMarket,
    getLocales,
    getMarkets,
    getResolution,
    getLegacyLanguage,
    getSDUIConfig,
} from './selectors/index';

export * as helpers from './lib/helpers';

export const actions = {
    setActiveLocale,
    setActiveMarket,
    setMarkets,
    setLocales,
    setMarketsEnvs,
    fetchMarketConfig,
    fetchSduiMarketConfig,
    setFetchPending,
    fetchStaticMarketConfig,
};
export const types = {
    MARKET_SET_ACTIVE_LOCALE,
    MARKET_SET_ACTIVE_MARKET,
    MARKET_SET_MARKETS,
    MARKET_SET_LOCALES,
    MARKET_SET_ENVS,
    MARKET_FETCH_CONFIG,
    MARKET_FETCH_CONFIG_PENDING,
    MARKET_FETCH_CONFIG_ERROR,
    MARKET_FETCH_CONFIG_SUCCESS,
    MARKET_FETCH_STATIC_CONFIG,
    MARKET_FETCH_STATIC_CONFIG_ERROR,
    MARKET_FETCH_STATIC_CONFIG_SUCCESS,
    MARKET_FETCH_SDUI_CONFIG,
    MARKET_FETCH_SDUI_CONFIG_ERROR,
    MARKET_FETCH_SDUI_CONFIG_PENDING,
    MARKET_FETCH_SDUI_CONFIG_SUCCESS,
};
/* export for backward compatibility, however these should be imported directly 
from @clearscore-group/lib.config.i18n */
export const constants = { GB, ZA, GLOBAL, MARKETS, EN_GB, EN_ZA, LOCALES };
export const reducers = { domain };
export const selectors = {
    getFetchStatus,
    getPredicate,
    getConfig,
    getLocales,
    getMarkets,
    getActiveMarket,
    getActiveLocale,
    getResolution,
    getLegacyLanguage,
    getSDUIConfig,
};

export const IDENTITY_IDENTIFY = 'CS/IDENTITY_IDENTIFY';
export const IDENTITY_IDENTIFY_SUCCESS = `${IDENTITY_IDENTIFY}_SUCCESS`;
export const IDENTITY_IDENTIFY_ERROR = `${IDENTITY_IDENTIFY}_ERROR`;
export const IDENTITY_REIDENTIFY = 'CS/IDENTITY_REIDENTIFY';
export const IDENTITY_REIDENTIFY_SUCCESS = `${IDENTITY_REIDENTIFY}_SUCCESS`;
export const IDENTITY_REIDENTIFY_ERROR = `${IDENTITY_REIDENTIFY}_ERROR`;
export const IDENTITY_RESTART = 'CS/IDENTITY_RESTART';
export const IDENTITY_RESTART_SUCCESS = `${IDENTITY_RESTART}_SUCCESS`;
export const IDENTITY_RESTART_ERROR = `${IDENTITY_RESTART}_ERROR`;
export const IDENTITY_GENERATE_TOKEN = 'CS/IDENTITY_GENERATE_TOKEN';
export const IDENTITY_GENERATE_TOKEN_SUCCESS = `${IDENTITY_GENERATE_TOKEN}_SUCCESS`;
export const IDENTITY_GENERATE_TOKEN_ERROR = `${IDENTITY_GENERATE_TOKEN}_ERROR`;
export const IDENTITY_VERIFY_OTP_TOKEN = 'CS/IDENTITY_VERIFY_OTP_TOKEN';
export const IDENTITY_VERIFY_OTP_TOKEN_SUCCESS = `${IDENTITY_VERIFY_OTP_TOKEN}_SUCCESS`;
export const IDENTITY_VERIFY_OTP_TOKEN_ERROR = `${IDENTITY_VERIFY_OTP_TOKEN}_ERROR`;
export const IDENTITY_CHOOSE = 'CS/IDENTITY_CHOOSE';
export const IDENTITY_CHOOSE_SUCCESS = `${IDENTITY_CHOOSE}_SUCCESS`;
export const IDENTITY_CHOOSE_ERROR = `${IDENTITY_CHOOSE}_ERROR`;
export const IDENTITY_FIRST_TIME_VISIT = 'CS/IDENTITY_FIRST_TIME_VISIT';
export const IDENTITY_FIRST_TIME_REGISTRATION = 'CS/IDENTITY_FIRST_TIME_REGISTRATION';

export const IDENTITY_FETCH_KBA = 'CS/IDENTITY_FETCH_KBA';
export const IDENTITY_FETCH_KBA_SUCCESS = `${IDENTITY_FETCH_KBA}_SUCCESS`;
export const IDENTITY_FETCH_KBA_ERROR = `${IDENTITY_FETCH_KBA}_ERROR`;
export const IDENTITY_VERIFY_KBA = 'CS/IDENTITY_VERIFY_KBA';
export const IDENTITY_VERIFY_KBA_SUCCESS = `${IDENTITY_VERIFY_KBA}_SUCCESS`;
export const IDENTITY_VERIFY_KBA_ERROR = `${IDENTITY_VERIFY_KBA}_ERROR`;

export const IDENTITY_VERIFY_BAV = 'CS/IDENTITY_VERIFY_BAV';
export const IDENTITY_VERIFY_BAV_SUCCESS = `${IDENTITY_VERIFY_BAV}_SUCCESS`;
export const IDENTITY_VERIFY_BAV_ERROR = `${IDENTITY_VERIFY_BAV}_ERROR`;
export const IDENTITY_SKIP_BAV = 'CS/IDENTITY_SKIP_BAV';
export const IDENTITY_SKIP_BAV_SUCCESS = `${IDENTITY_SKIP_BAV}_SUCCESS`;
export const IDENTITY_SKIP_BAV_ERROR = `${IDENTITY_SKIP_BAV}_ERROR`;

export const IDENTITY_MARKETING_USER = 'CS/IDENTITY_MARKETING_USER';
export const IDENTITY_SKIP_OTP = 'CS/IDENTITY_SKIP_OTP';
export const IDENTITY_SKIP_OTP_SUCCESS = `${IDENTITY_SKIP_OTP}_SUCCESS`;
export const IDENTITY_SKIP_OTP_ERROR = `${IDENTITY_SKIP_OTP}_ERROR`;

export const IDENTITY_SKIP_REIDENTIFY = 'CS/IDENTITY_SKIP_REIDENTIFY';
export const IDENTITY_SKIP_REIDENTIFY_SUCCESS = `${IDENTITY_SKIP_REIDENTIFY}_SUCCESS`;
export const IDENTITY_SKIP_REIDENTIFY_ERROR = `${IDENTITY_SKIP_REIDENTIFY}_ERROR`;

export const IDENTITY_EMPTY = 'CS/IDENTITY_EMPTY';

import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import { getCookie, setCookie } from '@clearscore-group/lib.helpers.cookies';

import { COOKIE_POLICY_ACCEPT } from '../cookie-policy.types';

const cookiePolicyAcceptanceCookie = 'CS_ACCEPT_COOKIES';

const initialState = {
    accepted: canUseDom() ? !!getCookie(cookiePolicyAcceptanceCookie) : true,
};

const setAcceptanceCookie = () => {
    const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setCookie(cookiePolicyAcceptanceCookie, 'true', {
        expires: oneYearFromNow,
    });
};

const domain = (state = initialState, action = {}) => {
    const { type } = action;

    switch (type) {
        case COOKIE_POLICY_ACCEPT:
            setAcceptanceCookie();
            return {
                ...state,
                accepted: true,
            };
        default:
            return state;
    }
};

export default domain;

import { AU, NZ } from '@clearscore-group/lib.config.i18n';

export default (market) => {
    switch (market) {
        case AU:
            return {
                reidentify: '/au-registration/v1/register/reidentify',
                skipReidentify: '/au-registration/v1/skip-update',
            };
        case NZ:
            return {
                reidentify: '/nz-registration/v1/register/reidentify',
                skipReidentify: '/nz-registration/v1/skip-update',
            };
        default:
            return {
                reidentify: '/cs-identity/v1/reidentify',
                skipReidentify: '/cs-identity/v1/reidentify',
            };
    }
};

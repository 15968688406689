type ProcessEnv = typeof process.env;

// Check if a feature is enabled. if no flag it is presumed disabled
const testFeature = (flag: string | boolean): boolean => ['true', true].includes(flag);

const FEATURE_FLAG_PREFIX = 'FEATURE_FLAG__';

const featureEnvKeys = (envs: ProcessEnv): string[] =>
    Object.keys(envs).filter((key) => key.startsWith(FEATURE_FLAG_PREFIX));

const getFeatureFlags = (envs: ProcessEnv = process.env): Record<string, boolean> =>
    featureEnvKeys(envs).reduce(
        (prev, key) => ({
            ...prev,
            [key.replace(FEATURE_FLAG_PREFIX, '')]: testFeature(envs[key]),
        }),
        {},
    );

const featureEnvs = (envs: ProcessEnv): Record<string, boolean> =>
    featureEnvKeys(envs).reduce((prev, key) => ({ ...prev, [key]: testFeature(envs[key]) }), {});

export { getFeatureFlags, featureEnvs };

export const LOGIN_OAUTH_SUBMIT = 'CS/LOGIN_OAUTH_SUBMIT';
export const LOGIN_OAUTH_SUBMIT_ERROR = 'CS/LOGIN_OAUTH_SUBMIT_ERROR';
export const LOGIN_OAUTH_SUBMIT_SUCCESS = 'CS/LOGIN_OAUTH_SUBMIT_SUCCESS';

export const LOGIN_OAUTH_AUTHORISE = 'CS/LOGIN_OAUTH_AUTHORISE';
export const LOGIN_OAUTH_AUTHORISE_ERROR = 'CS/LOGIN_OAUTH_AUTHORISE_ERROR';
export const LOGIN_OAUTH_AUTHORISE_SUCCESS = 'CS/LOGIN_OAUTH_AUTHORISE_SUCCESS';

export const LOGIN_OAUTH_REFRESH = 'CS/LOGIN_OAUTH_REFRESH';
export const LOGIN_OAUTH_REFRESH_ERROR = 'CS/LOGIN_OAUTH_REFRESH_ERROR';
export const LOGIN_OAUTH_REFRESH_SUCCESS = 'CS/LOGIN_OAUTH_REFRESH_SUCCESS';

export const SET_SESSION = 'CS/SET_SESSION';

// Native only event, we should never act on this action
export const NATIVE_SET_SESSION = 'CS/NATIVE_SET_SESSION';

export const TIMEOUT_SUCCESS = 'CS/TIMEOUT_SUCCESS';

export const LOGOUT_SUBMIT = 'CS/LOGOUT_SUBMIT';
export const LOGOUT_SUBMIT_ERROR = 'CS/LOGOUT_SUBMIT_ERROR';
export const LOGOUT_SUBMIT_SUCCESS = 'CS/LOGOUT_SUBMIT_SUCCESS';

export const CLEAR_SESSION = 'CS/CLEAR_SESSION';

export const SET_USER_ROLE = 'CS/SET_USER_ROLE';

import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getTncAgreedVersion = (state) => resolveProp('profile.data.tncAgreedVersion', state);
const getTncAgreedDate = (state) => resolveProp('profile.data.tncAgreedDate', state) || '';

export default createSelector(getTncAgreedVersion, getTncAgreedDate, (tncAgreedVersion, tncAgreedDate) => ({
    tncAgreedVersion,
    tncAgreedDate,
}));

import hasClientData from '@clearscore-group/lib.helpers.has-client-data';

import interpolateValues from './interpolateValues';

/**
 * check for url property and replace common variables
 * @param {object} action redux action
 * @param {object} store redux store
 * @returns {object} action redux action
 */
function _addVariables(action, store) {
    const {
        payload: { request },
    } = action;
    const state = store.getState();

    if (request.url) {
        request.url = interpolateValues(state, request.url);
    }

    return action;
}

/**
 * If the action payload has a client object, check and replace common variables.
 * @param {object} action redux action
 * @param {object} store redux store
 * @returns {function} function that takes an action and returns an action
 */
const addUrlVariables = (store) => (action) => hasClientData(action) ? _addVariables(action, store) : action;

export default addUrlVariables;

import getDevice, { getBrowser } from '@clearscore-group/lib.helpers.device';
import getEnv from '@clearscore-group/lib.helpers.envs';
import { sendToTrackingEventReceiver } from '@clearscore-group/lib.hooks.use-tracking';
import { MARKETS } from '@clearscore/config.i18n';
// eslint-disable-next-line import/no-internal-modules
import '@clearscore/ui.rainbow.tokens/tokens.css';
// eslint-disable-next-line import/no-internal-modules, @nx/enforce-module-boundaries
import '@clearscore/ui.rainbow.drivescore.tokens/tokens.css';
import type { GatsbyBrowser } from 'gatsby';
import ReactDOM from 'react-dom/client';
import { hydrate } from 'react-dom';

import { getSanitisedUrl } from './src/lib/helpers';
import ProviderWrapper from './src/components/provider-wrapper';

const market = process.env.MARKET || MARKETS[0];
const device = getDevice();
const PAGE_VIEW_WEBSITE_NAME = 'well_good_homepage';
const USE_TRACKING = [true, 'true'].includes(getEnv('TRACKING_EVENT_RECEIVER_ENABLED'));

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
    document.cookie = 'site_version=new;Path=/;Max-Age=2592000;';
    // it requires a polyfill for IE11 and android < 5
    if (typeof window.URLSearchParams === `undefined`) {
        import('url-search-params-polyfill');
    }
    if (typeof window.IntersectionObserver === 'undefined') {
        import('intersection-observer'); // needed for safari <=12.0
    }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
    const browser = getBrowser();
    const { url } = getSanitisedUrl();

    if (window.analytics) {
        window.analytics.page({
            cs_site: market,
            cs_ed: new Date(),
            device,
            website: PAGE_VIEW_WEBSITE_NAME,
        });
    }

    if (USE_TRACKING) {
        sendToTrackingEventReceiver({
            name: PAGE_VIEW_WEBSITE_NAME,
            event: 'page',
            app: 'website',
            props: {
                cs_site: market,
                cs_ed: new Date(),
                device,
                website: PAGE_VIEW_WEBSITE_NAME,
                path: window.location.pathname,
                browserName: browser.name,
                browserVersion: browser.version,
                location: url,
            },
        });
    }
};

export const replaceHydrateFunction = () => (element, container) => {
    if (process.env.NODE_ENV === 'production') {
        hydrate(element, container);
    } else {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    }
};

export const wrapRootElement = ProviderWrapper;

import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getMarketingRevisionVersion = (state) => resolveProp('config.MKT_PREF_VERSION', state);
const getTncVersion = (state) => parseInt(resolveProp('config.TERMSREVISIONNUMBER', state), 10);
const getImageCdn = (state) => resolveProp('config.IMAGECDN', state);
const getApiDomain = (state) => resolveProp('config.API_DOMAIN', state);
const getSegment = (state) => resolveProp('config.SEGMENT', state);
const getTrackingApi = (state) => resolveProp('config.API_TRACKING', state);
const getMediaUrl = (state) => resolveProp('config.MEDIA_URL', state);
const getImdb = (state) => ({
    key: getSegment(state),
    url: getTrackingApi(state),
});
const getEvents = (state) => ({
    imdb: getImdb(state),
});

// selector
export default createSelector(
    getMarketingRevisionVersion,
    getTncVersion,
    getImageCdn,
    getApiDomain,
    getEvents,
    getImdb,
    getMediaUrl,
    (marketingRevisionVersion, tncVersion, imageCdn, apiDomain, events, imdb, mediaUrl) => ({
        marketingRevisionVersion,
        tncVersion,
        imageCdn,
        apiDomain,
        events,
        imdb,
        mediaUrl,
    }),
);

import type { Dispatch, Middleware, Reducer, Store } from 'redux';
import { combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import createActionBridgeMiddleware from '@clearscore-group/lib.redux.action-bridge-middleware';
import type { Epic } from 'redux-observable';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import type { MemoryHistory, History } from 'history';
import { createMemoryHistory, createBrowserHistory } from 'history';
// import { createBrowserHistory } from 'history'; // <-- this does not work in verticals with v4.9.0
// import createBrowserHistory from 'history/createBrowserHistory';
import * as sessionTypes from '@clearscore-group/lib.constants.session-action-types';
import { reducers as marketReducers } from '@clearscore-group/lib.redux.market';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import { Provider as ReduxProvider } from 'react-redux';

// TODO: with our current setup they get included anyways
// only that these tools never get initialized. Once modules are ready
// we can conditionally handle this in a better way, like `import()` [TC]
import composeWithTools from './assets/development';

export const Provider = ReduxProvider;
export const Router = ConnectedRouter;
export const browserHistory = <S = unknown>(basename = ''): History<S> | MemoryHistory<S> =>
    canUseDom() ? createBrowserHistory<S>({ basename }) : createMemoryHistory<S>({ initialEntries: [basename || '/'] });

type NestedReducer = Record<string, Reducer>;

const isCombined = <S>(reducers?: S | Record<string, S>): reducers is S => typeof reducers === 'function';

export const combineNestedReducers = (reducers: NestedReducer): Reducer => {
    const nestedReducers = Object.keys(reducers).reduce(
        (prev, reducer) => ({
            ...prev,
            [reducer]: typeof reducers[reducer] === 'function' ? reducers[reducer] : combineReducers(reducers[reducer]),
        }),
        {},
    );
    return combineReducers(nestedReducers);
};

interface ComposeMiddleWaresOptions {
    pageMiddleWare: Middleware[];
    actionBridgeMiddleWare?: Middleware;
    epicMiddleWare?: Middleware;
    routerMiddleWare?: Middleware;
}

export const composeMiddleWares = ({
    pageMiddleWare = [],
    actionBridgeMiddleWare,
    epicMiddleWare,
    routerMiddleWare,
}: ComposeMiddleWaresOptions): Middleware[] =>
    [actionBridgeMiddleWare, epicMiddleWare, routerMiddleWare, ...pageMiddleWare].filter(
        (maybeMiddleware: Middleware | undefined): maybeMiddleware is Middleware => Boolean(maybeMiddleware),
    );

// Can't use `?` as optional argument, since `reducers` is not in last position so it has
// to be explicitly typed as `undefined`
const createRootReducer = (reducers: Reducer | NestedReducer | undefined, baseReducers: NestedReducer): Reducer => {
    const combinedReducers = isCombined<Reducer>(reducers)
        ? reducers
        : combineNestedReducers({ ...baseReducers, ...reducers });

    return (state, action) => {
        if ([sessionTypes.LOGOUT_SUBMIT, sessionTypes.TIMEOUT_SUCCESS].includes(action.type)) {
            return combinedReducers(undefined, action);
        }
        return combinedReducers(state, action);
    };
};

interface ConfigStoreOptions {
    initialState?: Record<string, unknown>;
    reducers?: Reducer | NestedReducer;
    name?: string;
    appBridgeTypes?: string[];
    coreDispatch?: Dispatch;
    pageMiddleWare?: Middleware[];
    pageEpics?: Record<string, Epic>;
    history?: History;
}

/**
 * Returns store
 * @param initialState
 * @param reducers
 * @param name
 * @param appBridgeTypes
 * @param coreDispatch
 * @param pageMiddleWare
 * @param pageEpics
 * @param history
 * @return Object
 * */
export default function configureStore({
    initialState = {},
    reducers,
    name,
    appBridgeTypes = [],
    coreDispatch,
    pageMiddleWare = [],
    pageEpics,
    history = browserHistory(),
}: ConfigStoreOptions = {}): Store {
    const epicMiddleWare = pageEpics && createEpicMiddleware();
    const actionBridgeMiddleWare = coreDispatch && createActionBridgeMiddleware(coreDispatch, appBridgeTypes);

    const middleWares = composeMiddleWares({
        pageMiddleWare,
        actionBridgeMiddleWare,
        epicMiddleWare,
        routerMiddleWare: routerMiddleware(history),
    });

    const rootReducer = createRootReducer(reducers, {
        router: connectRouter(history),
        market: marketReducers.domain,
    });

    const enhancer = composeWithTools({ name, middleWares });
    const store = createStore(rootReducer, initialState, enhancer);

    if (pageEpics) {
        epicMiddleWare?.run(isCombined(pageEpics) ? pageEpics : combineEpics(...Object.values(pageEpics)));
    }

    return store;
}

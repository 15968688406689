/**
 * Create an action object
 * @param {String} type Name of Redux action
 * @param {*} payload action payload
 * @return {{ type: string, payload?: any }} flux action format object
 */
export default function createAction(type, payload) {
    return {
        type,
        ...(payload && { payload }),
    };
}

import { AU, NZ } from '@clearscore-group/lib.config.i18n';

export default (market) => {
    switch (market) {
        case AU:
            return { url: '/au-registration/v1/prospect' };
        case NZ:
            return { url: '/nz-registration/v1/prospect' };
        default: {
            return { url: '/cs-registration/v1/prospect' };
        }
    }
};

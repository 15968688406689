/* eslint-disable */
export default ({ SESSIONCAM_ACTIVE }) => {
    if (typeof SESSIONCAM_ACTIVE === 'undefined') return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//d2oh4tlt9mrke9.cloudfront.net/Record/js/sessioncam.recorder.js';
    document.head.appendChild(script);
};

import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getUserStatus = (state) => resolveProp('profile.data.userStatus', state);
const getAllowSoftSearch = (state) =>
    resolveProp('profile.data.userPreferences.products.allowSoftSearch', state) || false;
const getWalkthrough = (state) => resolveProp('profile.data.userPreferences.ftw', state) || {};
const getAccountIDVStatus = (state) => resolveProp('profile.data.accountIDVStatus', state);
const getFailureReason = (state) => resolveProp('profile.data.failureReason', state);
const getEmploymentStatus = (state) => resolveProp('profile.data.employmentStatus', state);
const getResidentialStatus = (state) => resolveProp('profile.data.residentialStatus', state);
const getScoreBand = (state) => resolveProp('profile.data.userPreferences.scoreBand', state);
const getUserNovelty = (state) => resolveProp('profile.data.isNewUser', state);

export default createSelector(
    getAccountIDVStatus,
    getUserStatus,
    getFailureReason,
    getAllowSoftSearch,
    getWalkthrough,
    getEmploymentStatus,
    getResidentialStatus,
    getScoreBand,
    getUserNovelty,
    (
        accountIDVStatus,
        userStatus,
        failureReason,
        allowSoftSearch,
        walkthrough,
        employmentStatus,
        residentialStatus,
        scoreBand,
        isNewUser,
    ) => ({
        accountIDVStatus,
        userStatus,
        failureReason,
        allowSoftSearch,
        walkthrough,
        employmentStatus,
        residentialStatus,
        scoreBand,
        isNewUser,
    }),
);

import { createSelector } from 'reselect';
import resolveRequest, { getRequestStatus } from '@clearscore-group/lib.helpers.resolve-request';

const getFetchStatus = (state) => resolveRequest(state, 'profile.fetchStatus');
const getSaveStatus = (state) => resolveRequest(state, 'profile.saveStatus');
const getRemoveStatus = (state) => resolveRequest(state, 'profile.removeStatus');
const getReportAddressesFetchStatus = (state) => resolveRequest(state, 'profile.reportAddressesFetchStatus');
const getSaveCurrentAddressStatus = (state) => resolveRequest(state, 'profile.saveCurrentAddressStatus');
const getSendUserDataStatus = (state) => resolveRequest(state, 'profile.sendUserDataStatus');
const getResendEmailStatus = (state) => resolveRequest(state, 'profile.resendEmailStatus');
const getMarketingFetchStatus = (state) => resolveRequest(state, 'profile.mktgFetchStatus');
const getMarketingSaveStatus = (state) => resolveRequest(state, 'profile.mktgSaveStatus');
const getTermsSaveStatus = (state) => resolveRequest(state, 'profile.termsSaveStatus');

export default createSelector(
    getFetchStatus,
    getSaveStatus,
    getRemoveStatus,
    getReportAddressesFetchStatus,
    getSaveCurrentAddressStatus,
    getSendUserDataStatus,
    getResendEmailStatus,
    getMarketingFetchStatus,
    getMarketingSaveStatus,
    getTermsSaveStatus,
    (
        fetchStatus,
        saveStatus,
        removeStatus,
        reportAddressesFetchStatus,
        saveCurrentAddressStatus,
        sendUserDataStatusParam,
        resendEmailStatus,
        mktgFetchStatus,
        mktgSaveStatus,
        termsSaveStatus,
    ) => ({
        fetch: getRequestStatus(fetchStatus),
        save: getRequestStatus(saveStatus),
        remove: getRequestStatus(removeStatus),
        reportAddressesFetch: getRequestStatus(reportAddressesFetchStatus),
        saveCurrentAddress: getRequestStatus(saveCurrentAddressStatus),
        sendUserData: getRequestStatus(sendUserDataStatusParam),
        resendEmail: getRequestStatus(resendEmailStatus),
        mktgFetch: getRequestStatus(mktgFetchStatus),
        mktgSave: getRequestStatus(mktgSaveStatus),
        termsSave: getRequestStatus(termsSaveStatus),
    }),
);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import monitoring from '@clearscore-group/lib.helpers.monitoring';
import { EN_AU, EN_CA, EN_GB, EN_ZA, FR_CA, EN_NZ } from '@clearscore-group/lib.config.i18n';
import { constants, updateSessionCookie, getSessionCookie } from '@clearscore-group/lib.helpers.cookies';

import sessionCookieDetector from '../detectors/sessionCookie';
import marketPathDetector from '../detectors/marketPath';

const { CS_CURRENT_LANGUAGE } = constants;

const languageDetector = new LanguageDetector(null, {
    caches: ['cookie'],
    order: ['querystring', marketPathDetector.name, sessionCookieDetector.name, 'cookie', 'navigator'],
    lookupCookie: CS_CURRENT_LANGUAGE,
});

const supportedLngs = [EN_GB, EN_AU, EN_CA, EN_ZA, FR_CA, EN_NZ];

languageDetector.addDetector(marketPathDetector);
languageDetector.addDetector(sessionCookieDetector);

i18n.use(initReactI18next)
    .use(ICU)
    .use(languageDetector)
    .init({
        ns: [],
        resources: {
            EN_GB: {},
        },
        supportedLngs,
        lowerCaseLng: true,
        fallbackLng: EN_GB,
        interpolation: {
            escapeValue: false,
        },
        saveMissing: true,
        missingKeyHandler: (locale, ns, copyKey) => {
            // don't throw for copy tagged as coming from a feature service
            if (monitoring.hasMonitoring() && ns !== 'cs-dont-throw') {
                const errObject = new Error(`[i18n.missing-key] local: ${locale}, Scope: ${ns}, Copy-key: ${copyKey}`);
                monitoring.error(errObject);
            }
        },
    });

i18n.on('languageChanged', (lng) => {
    if (getSessionCookie()) updateSessionCookie({ locale: lng });
});

export default i18n;

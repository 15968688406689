import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getEmail = (state) => resolveProp('identity.email', state);
const getMaskedEmailData = (state) => resolveProp('identity.maskedEmailData.maskedEmailAddresses', state) || [];

export default createSelector(getEmail, getMaskedEmailData, (email, maskedEmailAddresses) => ({
    email,
    maskedEmailAddresses,
}));

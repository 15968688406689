import * as profileTypes from '@clearscore-group/lib.constants.profile-action-types';

import * as actionsImport from './actions';
import * as constantsImport from './constants';
import * as reducersImport from './reducers';
import * as selectorsImport from './selectors';

export const actions = actionsImport;
export const constants = constantsImport;
export const reducers = reducersImport;
export const selectors = selectorsImport;
export const types = profileTypes;

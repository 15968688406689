import * as requestStatuses from '@clearscore-group/lib.constants.request-statuses';
import { types as identityTypes } from '@clearscore-group/lib.redux.identity';
import {
    PROFILE_FETCH,
    PROFILE_FETCH_SUCCESS,
    PROFILE_FETCH_ERROR,
    PROFILE_SAVE,
    PROFILE_SAVE_SUCCESS,
    PROFILE_SAVE_ERROR,
    PROFILE_REMOVE,
    PROFILE_REMOVE_SUCCESS,
    PROFILE_REMOVE_ERROR,
    PROFILE_ERASE,
    PROFILE_ERASE_ERROR,
    PROFILE_ERASE_SUCCESS,
    PROFILE_FETCH_REPORT_ADDRESSES,
    PROFILE_FETCH_REPORT_ADDRESSES_SUCCESS,
    PROFILE_FETCH_REPORT_ADDRESSES_ERROR,
    PROFILE_SAVE_CURRENT_ADDRESS,
    PROFILE_SAVE_CURRENT_ADDRESS_SUCCESS,
    PROFILE_SAVE_CURRENT_ADDRESS_ERROR,
    PROFILE_SEND_USER_DATA,
    PROFILE_SEND_USER_DATA_SUCCESS,
    PROFILE_SEND_USER_DATA_ERROR,
    PROFILE_RESEND_EMAIL_VERIFICATION,
    PROFILE_RESEND_EMAIL_VERIFICATION_SUCCESS,
    PROFILE_RESEND_EMAIL_VERIFICATION_ERROR,
    PROFILE_MKTG_PREF_FETCH,
    PROFILE_MKTG_PREF_FETCH_SUCCESS,
    PROFILE_MKTG_PREF_FETCH_ERROR,
    PROFILE_MKTG_PREF_SAVE,
    PROFILE_MKTG_PREF_SAVE_SUCCESS,
    PROFILE_MKTG_PREF_SAVE_ERROR,
    PROFILE_SAVE_TERMS,
    PROFILE_SAVE_TERMS_SUCCESS,
    PROFILE_SAVE_TERMS_ERROR,
} from '@clearscore-group/lib.constants.profile-action-types';

const initialState = {
    fetchStatus: requestStatuses.INITIAL,
    saveCurrentAddressStatus: requestStatuses.INITIAL,
    reportAddressesFetchStatus: requestStatuses.INITIAL,
    sendUserDataStatus: requestStatuses.INITIAL,
    resendEmailStatus: requestStatuses.INITIAL,
    mktgFetchStatus: requestStatuses.INITIAL,
    mktgSaveStatus: requestStatuses.INITIAL,
};

const transformPhoneNumber = (requestData) => ({
    ...requestData,
    ...{
        phoneNumber: requestData.phoneNumber.number,
        phoneCountryCode: requestData.phoneNumber.countryCode,
        phoneType: requestData.phoneNumber.type,
    },
});

const transformSaveSuccessRequestData = (requestData) =>
    requestData.phoneNumber && typeof requestData.phoneNumber !== 'string'
        ? transformPhoneNumber(requestData)
        : requestData;

export default (state = initialState, action = {}) => {
    const { type, payload, requestData } = action;

    // N.B phoneNumber property in profile-service GET and PATCH methods isn't consistent
    // 1. .save() action is creating requestData passed from phone-form.container that's matching PATCH method
    // 2. phoneNumber data needs to be transformed back to its original shape provided by profile-service GET method
    const saveSuccessRequestData = requestData && transformSaveSuccessRequestData(requestData);

    switch (type) {
        case PROFILE_FETCH:
            return {
                ...state,
                fetchStatus: requestStatuses.PENDING,
            };
        case PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                data: {
                    ...payload,
                },
                fetchStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_FETCH_ERROR:
            return {
                ...state,
                fetchStatus: requestStatuses.ERROR,
            };
        case PROFILE_SAVE: {
            const { modified, ...stateWithoutModified } = state;
            return {
                ...stateWithoutModified,
                saveStatus: requestStatuses.PENDING,
            };
        }
        case PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...saveSuccessRequestData,
                },
                modified: payload.modified,
                saveStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_SAVE_ERROR:
            return {
                ...state,
                saveStatus: requestStatuses.ERROR,
            };
        case PROFILE_ERASE:
        case PROFILE_REMOVE:
            return {
                ...state,
                removeStatus: requestStatuses.PENDING,
            };
        case PROFILE_ERASE_SUCCESS:
        case PROFILE_REMOVE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...requestData,
                },
                removeStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_ERASE_ERROR:
        case PROFILE_REMOVE_ERROR:
            return {
                ...state,
                removeStatus: requestStatuses.ERROR,
            };
        case PROFILE_FETCH_REPORT_ADDRESSES:
            return {
                ...state,
                reportAddressesFetchStatus: requestStatuses.PENDING,
            };
        case PROFILE_FETCH_REPORT_ADDRESSES_SUCCESS:
            return {
                ...state,
                reportAddresses: [...Object.values(payload || {})],
                reportAddressesFetchStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_FETCH_REPORT_ADDRESSES_ERROR:
            return {
                ...state,
                reportAddressesFetchStatus: requestStatuses.ERROR,
            };
        case PROFILE_SAVE_CURRENT_ADDRESS:
            return {
                ...state,
                saveCurrentAddressStatus: requestStatuses.PENDING,
            };
        case PROFILE_SAVE_CURRENT_ADDRESS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...requestData,
                },
                saveCurrentAddressStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_SAVE_CURRENT_ADDRESS_ERROR:
            return {
                ...state,
                saveCurrentAddressStatus: requestStatuses.ERROR,
            };
        case PROFILE_SEND_USER_DATA:
            return {
                ...state,
                sendUserDataStatus: requestStatuses.PENDING,
            };
        case PROFILE_SEND_USER_DATA_SUCCESS:
            return {
                ...state,
                sendUserDataStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_SEND_USER_DATA_ERROR:
            return {
                ...state,
                sendUserDataStatus: requestStatuses.ERROR,
            };
        case PROFILE_RESEND_EMAIL_VERIFICATION:
            return {
                ...state,
                resendEmailStatus: requestStatuses.PENDING,
            };
        case PROFILE_RESEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                resendEmailStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_RESEND_EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                resendEmailStatus: requestStatuses.ERROR,
            };
        case PROFILE_MKTG_PREF_FETCH:
            return {
                ...state,
                mktgFetchStatus: requestStatuses.PENDING,
            };
        case PROFILE_MKTG_PREF_FETCH_SUCCESS:
            return {
                ...state,
                mktg: {
                    ...state.mktg,
                    ...payload,
                },
                mktgFetchStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_MKTG_PREF_FETCH_ERROR:
            return {
                ...state,
                mktgFetchStatus: requestStatuses.ERROR,
            };
        case PROFILE_MKTG_PREF_SAVE:
            return {
                ...state,
                mktgSaveStatus: requestStatuses.PENDING,
            };
        case PROFILE_MKTG_PREF_SAVE_SUCCESS:
            return {
                ...state,
                mktg: {
                    ...state.mktg,
                    ...requestData,
                },
                mktgSaveStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_MKTG_PREF_SAVE_ERROR:
            return {
                ...state,
                mktgSaveStatus: requestStatuses.ERROR,
            };
        case identityTypes.IDENTITY_IDENTIFY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    accountIDVStatus: payload.accountIDVStatus,
                },
            };
        case PROFILE_SAVE_TERMS: {
            const { modified, ...stateWithoutModified } = state;
            return {
                ...stateWithoutModified,
                termsSaveStatus: requestStatuses.PENDING,
            };
        }
        case PROFILE_SAVE_TERMS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...saveSuccessRequestData,
                },
                modified: payload.modified,
                termsSaveStatus: requestStatuses.SUCCESS,
            };
        case PROFILE_SAVE_TERMS_ERROR:
            return {
                ...state,
                termsSaveStatus: requestStatuses.ERROR,
            };
        default:
            return state;
    }
};

import { createSelector } from 'reselect';
import resolveRequest, { getRequestStatus } from '@clearscore-group/lib.helpers.resolve-request';

const getFetchStatus = (state) => resolveRequest(state, 'identity.fetchStatus');
const getRestartStatus = (state) => resolveRequest(state, 'identity.restartStatus');
const getChooseStatus = (state) => resolveRequest(state, 'identity.chooseStatus');
const getVerifyStatus = (state) => resolveRequest(state, 'identity.verifyStatus');
const getOtpVerifyStatus = (state) => resolveRequest(state, 'identity.otpVerifyStatus');
const getBavStatus = (state) => resolveRequest(state, 'identity.bavStatus');
const getReidentifyStatus = (state) => resolveRequest(state, 'identity.reidentifyStatus');
const getSkipReidentifyStatus = (state) => resolveRequest(state, 'identity.skipReidentifyStatus');
const getOtpSkipStatus = (state) => resolveRequest(state, 'identity.otpSkipStatus');

export default createSelector(
    getFetchStatus,
    getRestartStatus,
    getChooseStatus,
    getVerifyStatus,
    getOtpVerifyStatus,
    getBavStatus,
    getReidentifyStatus,
    getSkipReidentifyStatus,
    getOtpSkipStatus,
    (
        fetchStatus,
        restartStatus,
        chooseStatus,
        verifyStatus,
        otpVerifyStatus,
        bavStatus,
        reidentifyStatus,
        skipReidentifyStatus,
        otpSkipStatus,
    ) => ({
        fetch: getRequestStatus(fetchStatus),
        restart: getRequestStatus(restartStatus),
        choose: getRequestStatus(chooseStatus),
        verify: getRequestStatus(verifyStatus),
        otpVerify: getRequestStatus(otpVerifyStatus),
        bav: getRequestStatus(bavStatus),
        reidentify: getRequestStatus(reidentifyStatus),
        skipReidentify: getRequestStatus(skipReidentifyStatus),
        otpSkipStatus: getRequestStatus(otpSkipStatus),
    }),
);

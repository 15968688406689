import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getProspectId = (state) => resolveProp('prospect.prospectId', state);
const getEmail = (state) => resolveProp('prospect.email', state);
const getMarket = (state) => resolveProp('prospect.market', state) || resolveProp('prospect.region', state);
const getValidEmail = (state) => resolveProp('prospect.validEmail', state);
const getProspectHasAccount = (state) => resolveProp('prospect.prospectHasAccount', state);
const getAllowedUser = (state) => resolveProp('prospect.allowedUser', state);

const getUserMigrated = (state) => resolveProp('prospect.userMigrated', state);

export default createSelector(
    getProspectId,
    getEmail,
    getMarket,
    getValidEmail,
    getProspectHasAccount,
    getAllowedUser,
    getUserMigrated,
    (prospectId, email, market, validEmail, prospectHasAccount, allowedUser, userMigrated) => ({
        prospectId,
        email,
        market,
        validEmail,
        prospectHasAccount,
        allowedUser,
        userMigrated,
    }),
);

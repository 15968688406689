import getEnv from '@clearscore-group/lib.helpers.envs';
import { flatMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { getAnonymousId } from '@clearscore-group/lib.helpers.segment';
import { getPublisherCookie } from '@clearscore-group/lib.helpers.cookies';
import { types as prospectTypes, actions as prospectActions } from '@clearscore-group/lib.redux.prospect';
import { selectors as marketSelectors } from '@clearscore-group/lib.redux.market';

const getWebappUrl = () => getEnv('WEBAPP_URL');

const prospectError = (state) => {
    const market = marketSelectors.getActiveMarket(state);
    window.location.assign(`${getWebappUrl()}/signup?market=${market}`);
    return EMPTY;
};

const prospectCreate = (payload, state) => {
    const { email } = payload;
    if (!email) {
        return prospectError(state);
    }

    const anonymousId = getAnonymousId();
    const { publisherId, transactionId, campaignId } = getPublisherCookie();
    const market = marketSelectors.getActiveMarket(state);

    const pageUrl = window.location.pathname;
    const initialPageUrl = window.sessionStorage.getItem('initialPageUrl') || pageUrl;

    const prospectDetails = {
        email,
        anonymousId,
        ...(publisherId && { publisherId }),
        ...(transactionId && { transactionId }),
        ...(campaignId && { campaignId }),
        ...(pageUrl && { pageUrl }),
        ...(initialPageUrl && { initialPageUrl }),
    };
    return of(prospectActions.createProspect(prospectDetails, market));
};

const prospectSuccess = (payload, state) => {
    const { prospectId, prospectHasAccount, allowedUser, email } = payload;
    const isAlreadyRegistered = prospectHasAccount || !allowedUser;
    const market = marketSelectors.getActiveMarket(state);
    const url = isAlreadyRegistered
        ? `${getWebappUrl()}/login/already-registered?email=${email}`
        : `${getWebappUrl()}/step1/${market}/${prospectId}`;
    window.location.assign(url);
    return EMPTY;
};

const processSignUp = (state$) =>
    function theAction({ type, payload }) {
        switch (type) {
            case prospectTypes.PROSPECT_CREATE_SUCCESS:
                return prospectSuccess(payload, state$.value);
            case prospectTypes.PROSPECT_CREATE_ERROR:
                return prospectError(state$.value);
            case prospectTypes.PROSPECT_REGISTER:
            default:
                return prospectCreate(payload, state$.value);
        }
    };

const signUpEpic = (action$, state$) =>
    action$.pipe(
        ofType(
            prospectTypes.PROSPECT_REGISTER,
            prospectTypes.PROSPECT_CREATE_SUCCESS,
            prospectTypes.PROSPECT_CREATE_ERROR,
        ),
        flatMap(processSignUp(state$)),
    );

export default signUpEpic;

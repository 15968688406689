/**
 * Given an single pathref, resolve to the value of the given data object
 * @param {string} pathArr single property path
 * @param {object} obj object of data to resolve
 * @returns {object} object containing resolved data
 */
export function resolveProp(pathArr, obj) {
    if (arguments.length === 1) {
        return (objHolder) => resolveProp(pathArr, objHolder);
    }
    if (obj === null || obj === undefined) {
        return undefined;
    }
    let willReturn = obj;
    let counter = 0;

    const pathArrValue = typeof pathArr === 'string' ? pathArr.split('.') : pathArr;

    while (counter < pathArrValue.length) {
        if (willReturn === null || willReturn === undefined) {
            return undefined;
        }
        willReturn = willReturn[pathArrValue[counter]];
        counter += 1;
    }

    return willReturn;
}

/**
 * Given an object of object property paths and an object, resolves the paths to the values in the object
 * @param {object} paths object of property paths
 * @param {object} data object of data to resolve
 * @returns {object} object containing resolved data
 */

function resolveProps(paths, data) {
    return Object.keys(paths).reduce((resolvedData, prop) => {
        const pathRef = paths[prop];
        return {
            ...resolvedData,
            [prop]: resolveProp(pathRef, data),
        };
    }, {});
}

export default resolveProps;

import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import getEnv from '@clearscore-group/lib.helpers.envs';
import UAParser from 'ua-parser-js';
import currentDevice from 'current-device';

import { IOS_WEBVIEW, ANDROID_WEBVIEW, IOS_MOBILE, IOS_TABLET, ANDROID_MOBILE, ANDROID_TABLET } from './lib/constants';

const browserParsed = new UAParser().getResult();
const SERVER = 'server';
const NATIVE = 'native';
const MOBILE = 'mobile';
const WEBVIEW = 'WEBVIEW';

function getDevice() {
    if (!canUseDom()) {
        return SERVER;
    }

    const deviceLib = currentDevice.noConflict();
    const device = deviceLib.default || deviceLib;

    const isWebview = getEnv(WEBVIEW);
    const type = isWebview ? NATIVE : device.type;

    if (type === MOBILE) {
        return device.os;
    }
    return `${type}_${device.os}`;
}

export const isIOSWebview = () => getDevice() === IOS_WEBVIEW;
export const isAndroidWebview = () => getDevice() === ANDROID_WEBVIEW;

export const isIOSBrowser = () => [IOS_MOBILE, IOS_TABLET].includes(getDevice());
export const isAndroidBrowser = () => [ANDROID_MOBILE, ANDROID_TABLET].includes(getDevice());

export const isDesktop = () => getDevice().includes('desktop');

export const getBrowser = () => {
    const parser = new UAParser();
    const { browser } = parser.getResult();
    if (!browser.version) {
        // put within conditional so we don't have to update mocks in a tonne of vertical tests
        const ua = parser.getUA();
        if (ua.indexOf('Pingdom.com_bot') > -1) {
            const versionString = ua.replace('Pingdom.com_bot_version_', '').replace('_(http://www.pingdom.com/)', '');
            const [major, minor = 0] = versionString.split('.');
            const version = `${major}.${minor}`;
            return { name: 'pingdom', version };
        }
        return { name: 'unknown', version: 'unknown' };
    }

    const [major, minor = 0] = browser.version?.split('.') || ['unknown'];
    const version = `${major}.${minor}`;
    return {
        name: browser.name,
        version,
    };
};

getDevice.DEVICE_NAME = `${browserParsed.os.name} ${browserParsed.browser.name}`;

export * as deviceConstants from './lib/constants';

export default getDevice;

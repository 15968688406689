import createAction from '@clearscore-group/lib.helpers.create-action';
import createRequestPayload from '@clearscore-group/lib.helpers.create-request-payload';

import marketConfig from './lib/market-config';
import {
    IDENTITY_IDENTIFY,
    IDENTITY_REIDENTIFY,
    IDENTITY_RESTART,
    IDENTITY_GENERATE_TOKEN,
    IDENTITY_VERIFY_OTP_TOKEN,
    IDENTITY_CHOOSE,
    IDENTITY_FIRST_TIME_VISIT,
    IDENTITY_FIRST_TIME_REGISTRATION,
    IDENTITY_FETCH_KBA,
    IDENTITY_VERIFY_KBA,
    IDENTITY_VERIFY_BAV,
    IDENTITY_SKIP_BAV,
    IDENTITY_MARKETING_USER,
    IDENTITY_SKIP_OTP,
    IDENTITY_SKIP_REIDENTIFY,
} from './identity.types';

export const identify = (blackBox) =>
    createAction(
        IDENTITY_IDENTIFY,
        createRequestPayload('international', '/cs-identity/v1/identify', 'post', { beginBlackbox: blackBox }),
    );
export const reidentify = (market, blackBox) =>
    createAction(
        IDENTITY_REIDENTIFY,
        createRequestPayload(
            'international',
            marketConfig(market).reidentify,
            'post',
            blackBox
                ? {
                      beginBlackbox: blackBox,
                  }
                : undefined,
        ),
    );

export const skipReidentify = (market, blackBox) =>
    createAction(
        IDENTITY_SKIP_REIDENTIFY,
        createRequestPayload(
            'international',
            marketConfig(market).skipReidentify,
            'post',
            blackBox
                ? {
                      beginBlackbox: blackBox,
                  }
                : undefined,
        ),
    );

export const restart = () =>
    createAction(IDENTITY_RESTART, createRequestPayload('international', `/cs-identity/v1/restart`, 'post'));

export const generateToken = ({ isResend = false } = {}) =>
    createAction(
        IDENTITY_GENERATE_TOKEN,
        createRequestPayload('international', '/cs-identity/v2/otp/generate', 'post', null, { isResend }),
    );

export const verifyOtpToken = (data) =>
    createAction(
        IDENTITY_VERIFY_OTP_TOKEN,
        createRequestPayload('international', `/cs-identity/v1/otp/verify/${data.otpCandidate}`, 'post'),
    );
export const choose = (data) =>
    createAction(IDENTITY_CHOOSE, createRequestPayload('international', '/cs-identity/v1/choose', 'post', data));

export const firstTimeVisit = () => createAction(IDENTITY_FIRST_TIME_VISIT);

export const firstTimeRegistration = () => createAction(IDENTITY_FIRST_TIME_REGISTRATION);

export const fetchKba = () =>
    createAction(IDENTITY_FETCH_KBA, createRequestPayload('international', `/cs-identity/v1/kba`));

export const verifyKba = (data) =>
    createAction(
        IDENTITY_VERIFY_KBA,
        createRequestPayload('international', `/cs-identity/v1/kba/verify`, 'post', data),
    );

export const verifyBav = (data) =>
    createAction(
        IDENTITY_VERIFY_BAV,
        createRequestPayload('international', `/cs-identity/v1/bav/verify`, 'post', data),
    );

export const skipBav = () =>
    createAction(IDENTITY_SKIP_BAV, createRequestPayload('international', `/cs-identity/v1/bav/skip`, 'post'));

export const trackUserCameFromMarketingSource = ({ context }) => createAction(IDENTITY_MARKETING_USER, { context });

export const skipOtp = () =>
    createAction(IDENTITY_SKIP_OTP, createRequestPayload('international', `/cs-identity/v1/otp/skip`, 'post'));

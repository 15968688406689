import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

import getLegacyLanguageCode from './legacy/legacy.getLangCode';
import localesSelector from './getLocales';

const activeLocaleSelector = (state) => resolveProp('market.activeLocale', state);

export default createSelector(
    activeLocaleSelector,
    localesSelector,
    getLegacyLanguageCode,
    (locale = '', locales = [], legacyLocale = '') =>
        locales.find((item) => item === locale) || legacyLocale || 'en-gb',
);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors as marketSelectors } from '@clearscore-group/lib.redux.market';
import getDevice, { getBrowser } from '@clearscore-group/lib.helpers.device';

let _device;
const device = () => {
    if (!_device) {
        _device = getDevice();
    }

    return _device;
};

/**
 * Hook to get the default segment props that must be pulled from the redux store
 */
const useDefaultSegmentProps = () => {
    const market = useSelector(marketSelectors.getActiveMarket);
    const browser = getBrowser();
    return useMemo(
        () => ({
            device: device(),
            cs_site: market,
            browserName: browser.name,
            browserVersion: browser.version,
        }),
        [market, browser],
    );
};

export default useDefaultSegmentProps;

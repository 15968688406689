import {
    setCookie as setHelper,
    getCookie as getHelper,
    removeCookie as removeHelper,
    getAllCookies as getAllHelper,
} from './cookies';
import {
    setPublisherCookie as setPublisherHelper,
    getPublisherCookie as getPublisherHelper,
    getPublisherCookieUTM as getPublisherUTMHelper,
    publisherCookieIsSentToBackend as publisherIsSentToBackendHelper,
    markPublisherCookieAsSentToBackend as markPublisherAsSentToBackendHelper,
} from './cookies-publisher';
import {
    setPersonCookie as setPersonHelper,
    getPersonCookie as getPersonHelper,
    removePersonCookie as removePersonHelper,
} from './cs-person';
import {
    setVisitedSiteCookie as setVisitedSiteHelper,
    getVisitedSiteCookie as getVisitedSiteHelper,
    removeVisitedSiteCookie as removeVisitedSiteHelper,
} from './visited-site';
import {
    setSessionCookie as setSessionHelper,
    getSessionCookie as getSessionHelper,
    updateSessionCookie as updateSessionHelper,
    removeSessionCookie as removeSessionHelper,
} from './cookies-session';
import {
    getAlertCookie as getAlertHelper,
    setAlertCookie as setAlertHelper,
    hasCookieForAlert as hasCookieForAlertHelper,
} from './cookies-alerts';
import {
    removeAnonymousCookie as removeAnonymousCookieHelper,
    orchestrateAnonymousCookie as orchestrateAnonymousCookieHelper,
} from './anonymous-id';
import * as consts from './constants';

export const getAllCookies = getAllHelper;
export const setCookie = setHelper;
export const getCookie = getHelper;
export const removeCookie = removeHelper;
export const getPersonCookie = getPersonHelper;
export const setPersonCookie = setPersonHelper;
export const removePersonCookie = removePersonHelper;
export const getVisitedSiteCookie = getVisitedSiteHelper;
export const setVisitedSiteCookie = setVisitedSiteHelper;
export const removeVisitedSiteCookie = removeVisitedSiteHelper;
export const getPublisherCookie = getPublisherHelper;
export const setPublisherCookie = setPublisherHelper;
export const getPublisherCookieUTM = getPublisherUTMHelper;
export const publisherCookieIsSentToBackend = publisherIsSentToBackendHelper;
export const markPublisherCookieAsSentToBackend = markPublisherAsSentToBackendHelper;
export const setSessionCookie = setSessionHelper;
export const getSessionCookie = getSessionHelper;
export const updateSessionCookie = updateSessionHelper;
export const removeSessionCookie = removeSessionHelper;
export const getAlertCookie = getAlertHelper;
export const setAlertCookie = setAlertHelper;
export const hasCookieForAlert = hasCookieForAlertHelper;
export const removeAnonymousCookie = removeAnonymousCookieHelper;
export const orchestrateAnonymousCookie = orchestrateAnonymousCookieHelper;
export const constants = consts;

import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';
import * as requestStatuses from '@clearscore-group/lib.constants.request-statuses';

export const getRequestStatus = (status, statusCode) => ({
    isInitial: status === requestStatuses.INITIAL,
    isPending: status === requestStatuses.PENDING,
    isComplete: status === requestStatuses.SUCCESS,
    hasFailed: status === requestStatuses.ERROR,
    statusCode,
});

const calculateIsCompleteMultiple = (statusList) => {
    if (statusList.every((status) => status === requestStatuses.SUCCESS)) return true;
    if (statusList.every((status) => status === requestStatuses.ERROR)) return false;

    return statusList
        .map((status) => status === requestStatuses.SUCCESS || status === requestStatuses.ERROR)
        .every(Boolean);
};

export const getRequestStatusMultiple = (statusList = [], statusCodes = []) => {
    const hasStatuses = statusList.length;

    return {
        isInitial: hasStatuses ? statusList.every((status) => status === requestStatuses.INITIAL) : true,
        isPending: statusList.some((status) => status === requestStatuses.PENDING),
        isComplete: hasStatuses ? calculateIsCompleteMultiple(statusList) : false,
        hasFailed: hasStatuses ? statusList.every((status) => status === requestStatuses.ERROR) : false,
        hasFailures: statusList.some((status) => status === requestStatuses.ERROR),
        statusCodes,
    };
};

export default (state, prop) => {
    const props = prop.split('.');
    const hasValidRootReducer = props.length === 1 ? state : state[props[0]];
    if (hasValidRootReducer) {
        return resolveProp(prop, state) || requestStatuses.INITIAL;
    }
    console.error(`Cannot resolve root state for prop: ${prop}`); // eslint-disable-line no-console
    return requestStatuses.ERROR;
};

/* eslint-disable camelcase */
import { getSessionCookie } from '@clearscore-group/lib.helpers.cookies';

export const getSessionToken = () => {
    const { access_token, sessionToken } = getSessionCookie() || {};
    return access_token || sessionToken;
};

export const getRefreshToken = () => {
    const { refresh_token } = getSessionCookie() || {};
    return refresh_token;
};

export const getStructuredData = () => {
    const { access_token, sessionToken, refresh_token } = getSessionCookie() || {};
    return { authToken: access_token || sessionToken, refreshToken: refresh_token };
};

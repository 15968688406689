const MARKET = 'CS/MARKET';

export const MARKET_SET_MARKETS = `${MARKET}_SET_MARKETS`;
export const MARKET_SET_LOCALES = `${MARKET}_SET_LOCALES`;
export const MARKET_SET_ENVS = `${MARKET}_SET_ENVS`;

export const MARKET_SET_ACTIVE_MARKET = `${MARKET}_SET_ACTIVE_MARKET`;
export const MARKET_SET_ACTIVE_LOCALE = `${MARKET}_SET_ACTIVE_LOCALE`;

export const MARKET_FETCH_CONFIG = `${MARKET}_FETCH_CONFIG`;
export const MARKET_FETCH_CONFIG_PENDING = `${MARKET}_FETCH_CONFIG_PENDING`;
export const MARKET_FETCH_CONFIG_SUCCESS = `${MARKET_FETCH_CONFIG}_SUCCESS`;
export const MARKET_FETCH_CONFIG_ERROR = `${MARKET_FETCH_CONFIG}_ERROR`;

export const MARKET_FETCH_SDUI_CONFIG = `${MARKET}_FETCH_SDUI_CONFIG`;
export const MARKET_FETCH_SDUI_CONFIG_PENDING = `${MARKET}_FETCH_SDUI_CONFIG_PENDING`;
export const MARKET_FETCH_SDUI_CONFIG_SUCCESS = `${MARKET_FETCH_SDUI_CONFIG}_SUCCESS`;
export const MARKET_FETCH_SDUI_CONFIG_ERROR = `${MARKET_FETCH_SDUI_CONFIG}_ERROR`;

export const MARKET_FETCH_STATIC_CONFIG = `${MARKET}_FETCH_STATIC_CONFIG`;
export const MARKET_FETCH_STATIC_CONFIG_SUCCESS = `${MARKET_FETCH_STATIC_CONFIG}_SUCCESS`;
export const MARKET_FETCH_STATIC_CONFIG_ERROR = `${MARKET_FETCH_STATIC_CONFIG}_ERROR`;

import { AU, CA, GB, NZ, ZA } from '@clearscore/config.i18n';

/**
 * Used in SSG mode previews.
 */
export const PREVIEW_PATH = '__cs_preview__';

export const DEFAULT_MARKET_LOCALE = {
    [GB]: 'en-GB',
    [AU]: 'en-AU',
    [CA]: 'en-CA',
    [NZ]: 'en-NZ',
    [ZA]: 'en-ZA',
} as const;

export const PROD_BE_API_URL = 'https://app.clearscore.com';
export const CI_BE_API_URL = 'https://app-stg.cs55.co.uk:443';

/**
 * Check if action has correct client data structure in payload
 * @param {object} action redux action
 * @returns {boolean} if action structure is correct
 */
function hasClientData(action) {
    return !!(action.payload && action.payload.request && action.payload.request.client);
}

export default hasClientData;

import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import { removeAnonymousCookie, orchestrateAnonymousCookie } from '@clearscore-group/lib.helpers.cookies';

/**
 * Make sure segment exists adn has that function
 * @param {String} fcnName - Function name
 * @return {boolean} does the fucntion exist
 */
export function hasSegmentFunction(fcnName) {
    return canUseDom() ? window.analytics && typeof window.analytics[fcnName] === 'function' : false;
}

/**
 * Get the user anonymousID
 * @return {String} the user anonymousId
 */
export function getAnonymousId() {
    return orchestrateAnonymousCookie();
}

/**
 * Reset the user is including anonymousID
 * @return {void}
 */
export function resetUser() {
    removeAnonymousCookie();
    if (hasSegmentFunction('reset')) {
        window.analytics.reset();
    }
    // This is to reset the uuid cache in
    // packages/clearscore/apps/webapp/core/src/libs/hooks/use-identify-on-profile-fetch.js
    // To stop the uuid being null when the user logs out and then in as the cache was still set
    if (canUseDom()) {
        window.uuidCache = undefined;
    }
}

/**
 * Export the segment library by default
 */
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
export default canUseDom() ? window.analytics : false;

import { CurrencySymbol } from './currency-symbol';
import { Currency } from './currency';
import { DialingCode } from './dialing-code';
import { Locale } from './locale';
import { Market } from './market';
import { TimezoneAbbreviation } from './timezone-abbreviation';
import { Timezone } from './timezone';

/* MARKETS */
const GB = 'gb';
const ZA = 'za';
const AU = 'au';
const CA = 'ca';
const NZ = 'nz';
const GLOBAL = 'global';

const MARKETS = [GB, ZA, AU, CA, NZ] as const;

/* LOCALES */
const EN_GB = 'en-gb';
const EN_ZA = 'en-za';
const EN_AU = 'en-au';
const EN_CA = 'en-ca';
const EN_NZ = 'en-nz';
const FR_CA = 'fr-ca';

const LOCALES = [EN_GB, EN_ZA, EN_AU, EN_CA, EN_NZ, FR_CA] as const;

/* CURRENCIES */
const GBP = 'GBP';
const ZAR = 'ZAR';
const AUD = 'AUD';
const CAD = 'CAD';
const NZD = 'NZD';

const CURRENCY = {
    [GB]: GBP,
    [ZA]: ZAR,
    [AU]: AUD,
    [CA]: CAD,
    [NZ]: NZD,
} as const;

const GBP_SYMBOL = '£';
const ZAR_SYMBOL = 'R';
const AUD_SYMBOL = '$';
const CAD_SYMBOL = '$';
const NZD_SYMBOL = '$';

const CURRENCY_SYMBOLS = {
    [GBP]: GBP_SYMBOL,
    [ZAR]: ZAR_SYMBOL,
    [AUD]: AUD_SYMBOL,
    [CAD]: CAD_SYMBOL,
    [NZD]: NZD_SYMBOL,
} as const;

/* TIMEZONES */
const GB_TIMEZONE = 'Europe/London';
const ZA_TIMEZONE = 'Africa/Johannesburg';
const AU_TIMEZONE = 'Australia/Sydney';
const NZ_TIMEZONE = 'Pacific/Auckland';

const TIMEZONE = {
    [EN_GB]: GB_TIMEZONE,
    [EN_ZA]: ZA_TIMEZONE,
    [EN_AU]: AU_TIMEZONE,
    [EN_NZ]: NZ_TIMEZONE,
} as const;

/* TIMEZONE ABBREVIATIONS */
const GB_TIMEZONE_ABBREVIATION = 'GMT';
const BST_TIMEZONE_ABBREVIATION = 'BST';
const ZA_TIMEZONE_ABBREVIATION = 'SAST';
const AU_TIMEZONE_ABBREVIATION = 'AEST';
const NZ_TIMEZONE_ABBREVIATION = 'NZST';

const TIMEZONE_ABBREVIATION = {
    [EN_GB]: GB_TIMEZONE_ABBREVIATION,
    [EN_ZA]: ZA_TIMEZONE_ABBREVIATION,
    BST: BST_TIMEZONE_ABBREVIATION,
    [EN_AU]: AU_TIMEZONE_ABBREVIATION,
    [EN_NZ]: NZ_TIMEZONE_ABBREVIATION,
} as const;

/* DIALING CODES */
const GB_DIALING_CODE = '+44';
const ZA_DIALING_CODE = '+27';
const AU_DIALING_CODE = '+61';
const CA_DIALING_CODE = '+1';
const NZ_DIALING_CODE = '+64';

const DIALING_CODES = [GB_DIALING_CODE, ZA_DIALING_CODE, AU_DIALING_CODE, CA_DIALING_CODE, NZ_DIALING_CODE] as const;

const DIALING_CODE = {
    [GB]: GB_DIALING_CODE,
    [ZA]: ZA_DIALING_CODE,
    [AU]: AU_DIALING_CODE,
    [CA]: CA_DIALING_CODE,
    [NZ]: NZ_DIALING_CODE,
} as const;

/* OLD CONSTANTS (js) */
export {
    GB,
    ZA,
    AU,
    CA,
    NZ,
    GLOBAL,
    EN_GB,
    EN_ZA,
    EN_AU,
    EN_CA,
    EN_NZ,
    FR_CA,
    GBP,
    ZAR,
    AUD,
    CAD,
    NZD,
    GBP_SYMBOL,
    ZAR_SYMBOL,
    AUD_SYMBOL,
    CAD_SYMBOL,
    NZD_SYMBOL,
    MARKETS,
    LOCALES,
    CURRENCY,
    CURRENCY_SYMBOLS,
    TIMEZONE,
    TIMEZONE_ABBREVIATION,
    DIALING_CODES,
    DIALING_CODE,
};

/* NEW CONSTANTS (ts) */
export { CurrencySymbol, Currency, DialingCode, Locale, Market, TimezoneAbbreviation, Timezone };

// workaround for bundling issues
export default {
    /* OLD CONSTANTS (js) */
    GB,
    ZA,
    AU,
    CA,
    NZ,
    GLOBAL,
    EN_GB,
    EN_ZA,
    EN_AU,
    EN_CA,
    EN_NZ,
    FR_CA,
    GBP,
    ZAR,
    AUD,
    CAD,
    NZD,
    GBP_SYMBOL,
    ZAR_SYMBOL,
    AUD_SYMBOL,
    CAD_SYMBOL,
    NZD_SYMBOL,
    MARKETS,
    LOCALES,
    CURRENCY,
    CURRENCY_SYMBOLS,
    TIMEZONE,
    TIMEZONE_ABBREVIATION,
    DIALING_CODES,
    DIALING_CODE,
};

// Allows us to dynamically set env vars on staging for testing (falls back to prod).
export default ():
    | {
          INTERNATIONAL_API_DOMAIN: string;
          WEBAPP_URL: string;
          WEBAPI_URL: string;
          COOKIE_DOMAIN: string;
          SEGMENT_ID: string;
          PERIMETERX_APP_SRC: string;
          FEATURE_FLAG__ENABLE_PERIMETERX?: string;
      }
    | {
          INTERNATIONAL_API_DOMAIN?: undefined;
          WEBAPP_URL?: undefined;
          WEBAPI_URL?: undefined;
          COOKIE_DOMAIN?: undefined;
          SEGMENT_ID?: undefined;
          PERIMETERX_APP_SRC?: undefined;
          FEATURE_FLAG__ENABLE_PERIMETERX?: undefined;
      } => {
    const isStaging =
        typeof window !== `undefined` &&
        typeof document !== `undefined` &&
        document?.domain &&
        document?.domain.includes('cs55.co.uk');
    if (isStaging) {
        return {
            INTERNATIONAL_API_DOMAIN:
                process.env.INTERNATIONAL_API_DOMAIN_STAGING || 'https://app.clearscore.com/api/@market@',
            WEBAPP_URL: process.env.WEBAPP_URL_STAGING || 'https://app.clearscore.com',
            WEBAPI_URL: process.env.WEBAPI_URL_STAGING || 'https://app.clearscore.com/api',
            COOKIE_DOMAIN: process.env.COOKIE_DOMAIN_STAGING || '.clearscore.com',
            SEGMENT_ID: process.env.SEGMENT_ID_STAGING || 'ZvtC31nqOaIOf7NxJLqTHc4r07tpSpws',
            PERIMETERX_APP_SRC: process.env.PERIMETERX_APP_SRC_STAGING || '/mgcGz2Z8/init.js',
            FEATURE_FLAG__ENABLE_PERIMETERX: process.env.FEATURE_FLAG__ENABLE_PERIMETERX || 'true',
        };
    }
    return {};
};

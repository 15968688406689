import { createSelector } from 'reselect';
import resolveRequest, { getRequestStatus } from '@clearscore-group/lib.helpers.resolve-request';

import getFetchStatus from './getFetchStatus';

const getCheckStatus = (state) => resolveRequest(state, 'market.checkStatus');

export default createSelector(getCheckStatus, getFetchStatus, (checkStatus, fetchStatus) => ({
    check: getRequestStatus(checkStatus),
    fetch: fetchStatus,
}));

import type { Breadcrumb, Extra, Primitive, Scope } from '@sentry/types';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';

const CACHE_NAME = '__cs__';
const MONITORING_NAME = 'monitoring';

/**
 * helper to check if monitoring is available
 */
function hasMonitoring() {
    return canUseDom() && window[CACHE_NAME] && window[CACHE_NAME][MONITORING_NAME];
}

function pageview(location: unknown) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.pageview?.(location);
}

function identity(uuid: string) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.identity?.(uuid);
}

function tag(name: string, value: Primitive) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.tag?.(name, value);
}

function property(name: string, value: Extra) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.property?.(name, value);
}

function addBreadcrumb(data: Breadcrumb) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.addBreadcrumb?.(data);
}

function error(err?: string) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.error?.(err);
}

function log(msg: string) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.log?.(msg);
}

function withScope(func: (scope: Scope) => void) {
    if (hasMonitoring()) window[CACHE_NAME][MONITORING_NAME]?.withScope?.(func);
}

export default {
    hasMonitoring,
    identity,
    tag,
    property,
    addBreadcrumb,
    error,
    log,
    withScope,
    pageview,
};

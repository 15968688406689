import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getAnnualIncome = (state) => resolveProp('profile.data.annualIncome', state);
const getEmploymentStatus = (state) => resolveProp('profile.data.employmentStatus', state);
const getResidentialStatus = (state) => resolveProp('profile.data.residentialStatus', state);
const getAddresses = (state) => {
    const addresses = [resolveProp('profile.data.address', state)];
    const previousAddresses = resolveProp('profile.data.previousAddresses', state);
    if (previousAddresses && previousAddresses.length) addresses.push(...previousAddresses);
    return addresses;
};

export default createSelector(
    getAnnualIncome,
    getEmploymentStatus,
    getResidentialStatus,
    getAddresses,
    (annualIncome, employmentStatus, residentialStatus, addresses) => ({
        annualIncome,
        employmentStatus,
        residentialStatus,
        addresses,
    }),
);

import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getMobileNumber = (state) => resolveProp('identity.mobileNumber', state);
const getMaskedNumberData = (state) => resolveProp('identity.maskedNumberData.maskedNumbers', state) || [];

export default createSelector(getMobileNumber, getMaskedNumberData, (mobileNumber, maskedNumbers) => ({
    mobileNumber,
    maskedNumbers,
}));

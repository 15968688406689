import { getCookie, setCookie } from './cookies';
import { CS_PUBLISHER_ID, EXPIRE_3DAYS } from './constants';

/**
 * Directly set the publisher cookie to `body` with no validation
 */
const setPublisherCookieValue = (body) =>
    setCookie(CS_PUBLISHER_ID, body, {
        expires: new Date(Date.now() + EXPIRE_3DAYS),
    });

/**
 * Sets the CS_PUBLISHER_ID cookie if utm_source + utm_medium is present in search params
 * @param {String} searchParams
 */
export const setPublisherCookie = (searchParams = '') => {
    const urlParams = new URLSearchParams(searchParams);
    const publisherId = urlParams.get('utm_source');
    const transactionId = urlParams.get('utm_medium');
    const campaignId = urlParams.get('utm_campaign');
    if (publisherId && transactionId) {
        setPublisherCookieValue({
            publisherId,
            transactionId,
            ...(campaignId && { campaignId }),
        });
    }
};

/**
 * Returns CS_PUBLISHER_ID cookie or {} if it does not exist
 */
export const getPublisherCookie = () => getCookie(CS_PUBLISHER_ID) || {};

/**
 * Returns CS_PUBLISHER_ID cookie (if present) as the original map
 * of `utm_xxx` to value as opposed to the mappings above (`publisherId` etc.)
 */
export const getPublisherCookieUTM = () => {
    const { publisherId, transactionId, campaignId, sentToBackend } = getPublisherCookie();

    if (!publisherId || !transactionId) {
        return {};
    }

    return {
        utm_source: publisherId,
        utm_medium: transactionId,
        ...(campaignId && { utm_campaign: campaignId }),
        ...(sentToBackend && { sentToBackend }),
    };
};

/**
 * Check if a given publisher cookie has been sent to the backend
 * @param {Object} publisherCookie Publisher cookie as returned from `getPublisherCookie`
 */
export const publisherCookieIsSentToBackend = (publisherCookie) => !!publisherCookie.sentToBackend;

/**
 * Mark the publisher cookie as "sent to the backend" so we can avoid sending it again
 */
export const markPublisherCookieAsSentToBackend = () => {
    const pubCookie = getPublisherCookie();

    if (Object.entries(pubCookie).length > 0 && !publisherCookieIsSentToBackend(pubCookie)) {
        setPublisherCookieValue({
            ...pubCookie,
            sentToBackend: true,
        });
    }
};

const CACHE_NAME = '__cs__';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const objGlobal = typeof window !== 'undefined' && window.document && window.document.createElement ? window : {};

objGlobal[CACHE_NAME] = objGlobal[CACHE_NAME] || {};
objGlobal[CACHE_NAME].verticals = objGlobal[CACHE_NAME].verticals || {};
objGlobal[CACHE_NAME].envs = objGlobal[CACHE_NAME].envs || {};

export const cache = objGlobal[CACHE_NAME];

export function loadRoutes() {
    return cache.routes || [];
}

export function hasComponent(name) {
    const vertical = cache.verticals[name];
    return !!vertical;
}

export function loadComponent(name) {
    const vertical = cache.verticals[name];
    return vertical ? vertical.Component : undefined;
}

export function getEntry(key) {
    if (cache && cache[key]) {
        return cache[key];
    }
    return null;
}

export function setEntry(key, value) {
    cache[key] = value;
    return getEntry(key);
}

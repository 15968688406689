import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';
import { formatAddress } from '@clearscore-group/lib.normalize.address';
import { selectors as marketSelectors } from '@clearscore-group/lib.redux.market';

const year = 365;
const day = 24;
const hour = 60;
const minute = 60;
const second = 1000;
const millisInYear = second * minute * hour * day * year;

const getAccountId = (state) => resolveProp('profile.data.accountId', state);
const getUuid = (state) => resolveProp('profile.data.uuid', state);
const getEmail = (state) => resolveProp('profile.data.email', state);
const getEmailVerified = (state) => resolveProp('profile.data.emailVerified', state);
const getFirstName = (state) => resolveProp('profile.data.firstName', state);
const getMiddleNames = (state) => resolveProp('profile.data.middleNames', state);
const getLastName = (state) => resolveProp('profile.data.lastName', state);
const getDOB = (state) => {
    const dob = resolveProp('profile.data.dob', state) || '';
    return dob.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1');
};
const getAddress = (state) => resolveProp('profile.data.address', state) || {};
const getAddressId = (state) => resolveProp('profile.data.address.addressId', state) || {};
const getAddressBuildingName = (state) => resolveProp('profile.data.address.buildingName', state);
const getAddressFlatNumber = (state) => resolveProp('profile.data.address.flatNumber', state);
const getAddressRoad = (state) => resolveProp('profile.data.address.road', state);
const getAddressPinCode = (state) => resolveProp('profile.data.address.pinCode', state);
const getAddressCity = (state) => resolveProp('profile.data.address.city', state);
const getAddressState = (state) => resolveProp('profile.data.address.state', state);
const getAddressCurrent = (state) => resolveProp('profile.data.address.currentAddress', state);
const getPreviousAddresses = (state) => resolveProp('profile.data.previousAddresses', state) || [];
const getPhoneNumber = (state) => resolveProp('profile.data.phoneNumber', state);
const getPhoneCountryCode = (state) => resolveProp('profile.data.phoneCountryCode', state);
const getPhoneType = (state) => resolveProp('profile.data.phoneType', state);
const getPhoneVerified = (state) => resolveProp('profile.data.phoneVerified', state);
const getGender = (state) => resolveProp('profile.data.gender', state);
const getIDDocuments = (state) => resolveProp('profile.data.idDocuments', state);
const getEmployerName = (state) => resolveProp('profile.data.employerName', state);
const getMonthlyIncome = (state) => resolveProp('profile.data.income.amount', state);
const marketSelector = (state) => marketSelectors.getActiveMarket(state);

export default createSelector(
    getAccountId,
    getUuid,
    getEmail,
    getEmailVerified,
    getFirstName,
    getMiddleNames,
    getLastName,
    getDOB,
    getAddress,
    getAddressId,
    getAddressBuildingName,
    getAddressFlatNumber,
    getAddressRoad,
    getAddressPinCode,
    getAddressCity,
    getAddressState,
    getAddressCurrent,
    getPreviousAddresses,
    getPhoneNumber,
    getPhoneCountryCode,
    getPhoneType,
    getPhoneVerified,
    getGender,
    getIDDocuments,
    getEmployerName,
    getMonthlyIncome,
    marketSelector,
    (
        accountId,
        uuid,
        email,
        emailVerified,
        firstName,
        middleNames,
        lastName,
        dob,
        address,
        addressId,
        addressBuildingName,
        addressFlatNumber,
        addressRoad,
        addressPinCode,
        addressCity,
        addressState,
        addressCurrent,
        previousAddresses,
        phoneNumber,
        phoneCountryCode,
        phoneType,
        phoneVerified,
        gender,
        idDocuments,
        employerName,
        monthlyIncome,
        market,
    ) => {
        // dob formatted as yyy-mm-dd within selector. happy days.
        const ageInYears = (new Date() - new Date(dob)) / millisInYear;
        const age = Math.floor(ageInYears);
        const pan = ((idDocuments || []).find(({ type }) => type === 'PAN') || {}).value;
        const addressLineOne =
            addressCurrent || [addressFlatNumber, addressBuildingName, addressRoad].filter(Boolean).join(', ');

        return {
            accountId,
            uuid,
            email,
            emailVerified,
            firstName,
            middleNames,
            lastName,
            dob,
            age,
            phoneNumber,
            phoneCountryCode,
            phoneType,
            phoneVerified,
            gender,
            pan,
            employerName,
            monthlyIncome,
            address,
            previousAddresses,
            addressLineOne,
            addressCity,
            addressState,
            addressPinCode,
            idDocuments,
            fullName: [firstName, middleNames, lastName].filter(Boolean).join(' '),
            addressId,
            fullAddress: formatAddress(address, market),
            previousFullAddresses: previousAddresses.map((prevAddress) => formatAddress(prevAddress, market)),
        };
    },
);

/* eslint-disable no-useless-escape */

import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

/**
 * Predicate to evaluate if given value is a string beginning with @
 * @param {string} val to check
 * @returns {boolean} if item is object
 */
const _shouldLookupProperty = (val) => typeof val === 'string' && val.startsWith('@');

/**
 * Look through given url string and attempt to resolve any values beginning with @ from given context
 * @param {Object} context state to resolve values from
 * @param {string} urlString url string
 * @returns {string} URL with values interpolated
 */
const interpolateValues = (context, urlString = '') => {
    let interpolatedUrlString = urlString;

    function _resolveProp(prefixedPath) {
        const isOptionalProp = prefixedPath.startsWith('?@');
        const prefixLength = isOptionalProp ? 2 : 1;
        const resolvedProp = resolveProp(prefixedPath.substring(prefixLength), context);
        if (!resolvedProp) {
            return isOptionalProp ? null : prefixedPath;
        }

        return resolvedProp;
    }

    const matches = interpolatedUrlString.match(/(@.[^\/]*)/);

    if (matches && matches.length) {
        matches.forEach((match) => {
            const resolvedProp = _shouldLookupProperty(match) ? _resolveProp(match) : match;
            interpolatedUrlString = resolvedProp
                ? interpolatedUrlString.replace(match, resolvedProp)
                : interpolatedUrlString;
        });
    }

    return interpolatedUrlString;
};

export default interpolateValues;

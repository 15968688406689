import getSentryConfig from './get-sentry-config';
import initMonitoring from './monitoring';

interface ILoadSentry {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (a: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any[];
}

interface IAsyncSentry {
    market: string;
    SENTRY_ID: string;
}

// lazy load script wrapped up in function
export default ({ market, SENTRY_ID }: IAsyncSentry): void => {
    const scriptTag = 'script';
    const onError = 'onerror';
    const onUnhandledRejection = 'onunhandledrejection';
    const sentryNamespace = 'Sentry';
    const bundleUrl = 'https://browser.sentry-cdn.com/6.16.1/bundle.min.js';
    // TODO: get this from env files
    const organizationProject = SENTRY_ID.match(/^https:\/\/(\w*)/)[1];
    const initOptions = getSentryConfig({
        SENTRY_ID,
    });
    let shouldLazyLoad = true;
    const sentryOnloadHandlers = [];

    const loadSentry: ILoadSentry = (errorMessage) => {
        if (
            (errorMessage.e ||
                errorMessage.p ||
                (errorMessage.f && errorMessage.f.indexOf('capture') > -1) ||
                (errorMessage.f && errorMessage.f.indexOf('showReportDialog') > -1)) &&
            shouldLazyLoad
        ) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            loadSentryScript(sentryOnloadHandlers);
        }
        loadSentry.data.push(errorMessage);
    };
    loadSentry.data = [];
    const originalWindowOnErrorHandler = window[onError];
    window[onError] = (...args): void => {
        loadSentry({
            e: args,
        });
        if (originalWindowOnErrorHandler) {
            originalWindowOnErrorHandler.apply(window, args);
        }
    };
    const originalWindowOnUnhandledRejectionHandler = window[onUnhandledRejection];
    window[onUnhandledRejection] = (a, ...args): void => {
        loadSentry({
            p: a.reason,
        });
        if (originalWindowOnUnhandledRejectionHandler) {
            originalWindowOnUnhandledRejectionHandler.apply(window, [a, ...args]);
        }
    };

    let loaded = false;

    function initSentry(onloadCallbacks, _Sentry): void {
        try {
            const loadSentryData = loadSentry.data;
            for (let callbackIndex = 0; callbackIndex < onloadCallbacks.length; callbackIndex += 1) {
                if (typeof onloadCallbacks[callbackIndex] === 'function') {
                    onloadCallbacks[callbackIndex]();
                }
            }
            let skipSentryInit = false;
            let sentryMetaLoaded = !!window.__SENTRY__;
            if (sentryMetaLoaded && window.__SENTRY__.hub && window.__SENTRY__.hub.getClient()) {
                skipSentryInit = true;
            }
            sentryMetaLoaded = false;
            for (let b = 0; b < loadSentryData.length; b += 1) {
                if (loadSentryData[b].f) {
                    sentryMetaLoaded = true;
                    const currentLoadSentryData = loadSentryData[b];
                    if (!skipSentryInit && currentLoadSentryData.f !== 'init') {
                        _Sentry.init();
                    }
                    skipSentryInit = true;
                    // eslint-disable-next-line prefer-spread
                    _Sentry[currentLoadSentryData.f].apply(_Sentry, currentLoadSentryData.a);
                }
            }

            if (!skipSentryInit && !sentryMetaLoaded) {
                _Sentry.init();
            }

            const windowOnErrorHandler = window[onError];
            const windowOnUnhandledRejectionHandler = window[onUnhandledRejection];
            for (let loadSentryDataIndex = 0; loadSentryDataIndex < loadSentryData.length; loadSentryDataIndex += 1) {
                // eslint-disable-next-line no-unused-expressions
                loadSentryData[loadSentryDataIndex].e && windowOnErrorHandler
                    ? windowOnErrorHandler.apply(window, loadSentryData[loadSentryDataIndex].e)
                    : loadSentryData[loadSentryDataIndex].p &&
                      windowOnUnhandledRejectionHandler &&
                      windowOnUnhandledRejectionHandler.apply(window, [loadSentryData[loadSentryDataIndex].p]);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
    function loadSentryScript(a): void {
        if (!loaded) {
            loaded = true;
            const firstScriptTag = document.getElementsByTagName(scriptTag)[0];
            const loaderScriptElement = document.createElement(scriptTag);
            loaderScriptElement.src = bundleUrl;
            loaderScriptElement.crossOrigin = 'anonymous';
            loaderScriptElement.addEventListener('load', () => {
                try {
                    window[onError] = originalWindowOnErrorHandler;
                    window[onUnhandledRejection] = originalWindowOnUnhandledRejectionHandler;
                    const _Sentry = window[sentryNamespace];
                    const sentryInit = _Sentry.init;
                    _Sentry.init = (originalInitOptions): void => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const option in originalInitOptions) {
                            if (Object.prototype.hasOwnProperty.call(originalInitOptions, option)) {
                                initOptions[option] = originalInitOptions[option];
                            }
                        }
                        sentryInit(initOptions);
                        initMonitoring({ Sentry: _Sentry, market });
                    };
                    initSentry(a, _Sentry);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            });
            firstScriptTag.parentNode.insertBefore(loaderScriptElement, firstScriptTag);
        }
    }

    let forceLoaded = false;
    for (let scriptIndex = 0; scriptIndex < document.scripts.length; scriptIndex += 1) {
        if (document.scripts[scriptIndex].src.includes(organizationProject)) {
            shouldLazyLoad = document.scripts[scriptIndex].getAttribute('data-lazy') !== 'no';
            break;
        }
    }
    window[sentryNamespace] = window[sentryNamespace] || {};
    window[sentryNamespace].onLoad = (a): void => {
        sentryOnloadHandlers.push(a);
        if (shouldLazyLoad && !forceLoaded) {
            loadSentryScript(sentryOnloadHandlers);
        }
    };
    window[sentryNamespace].forceLoad = (): void => {
        forceLoaded = true;
        if (shouldLazyLoad) {
            setTimeout(() => {
                loadSentryScript(sentryOnloadHandlers);
            });
        }
    };
    'init addBreadcrumb captureMessage captureException captureEvent configureScope withScope showReportDialog'
        .split(' ')
        .forEach((a) => {
            window[sentryNamespace][a] = (...args): void => {
                loadSentry({
                    f: a,
                    a: args,
                });
            };
        });

    // TODO: figure out how to get this to work with if
    // eslint-disable-next-line no-unused-expressions
    shouldLazyLoad ||
        setTimeout(() => {
            loadSentryScript(sentryOnloadHandlers);
        });
};

/* eslint-disable react/prop-types */
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import configureStore, { Router, Provider, browserHistory } from '@clearscore-group/lib.redux.store';
import { selectors as marketSelectors } from '@clearscore-group/lib.redux.market';

import i18n from './lib/i18n';

const isFunction = (config) => typeof config === 'function';

export const i18nInstance = i18n;

export default ({
    children,
    storeConfig,
    i18n: newI18nInstance = i18n, // allow modified instance for testing
    defaultMarket,
    defaultLocale,
    basename,
    history = browserHistory(basename),
    ...props
}) => {
    const store = isFunction(storeConfig)
        ? storeConfig
        : configureStore({ ...storeConfig, defaultLocale, defaultMarket, history });
    const state = store.getState();
    const locale = marketSelectors.getActiveLocale(state);
    const providerProps = { store, history, locale, ...props };
    const getChild = () => (isFunction(children) ? children(providerProps) : children);

    return (
        <Provider {...providerProps}>
            <Router history={history}>
                <I18nextProvider i18n={newI18nInstance}>{getChild()}</I18nextProvider>
            </Router>
        </Provider>
    );
};

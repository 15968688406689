import { getCookie, setCookie, removeCookie } from './cookies';
import { VISITED_SITE_COOKIE_NAME } from './constants';

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const oneYear = day * 365;

export const setVisitedSiteCookie = (userIdentifier) => {
    setCookie(VISITED_SITE_COOKIE_NAME, userIdentifier, {
        expires: new Date(new Date().getTime() + oneYear),
    });
};

export const getVisitedSiteCookie = () => getCookie(VISITED_SITE_COOKIE_NAME);

export const removeVisitedSiteCookie = () => removeCookie(VISITED_SITE_COOKIE_NAME);

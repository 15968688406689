// Generate UUID
function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function getId() {
    // eslint-disable-next-line no-undef
    return typeof crypto !== 'undefined' && typeof crypto?.randomUUID === 'function'
        ? // eslint-disable-next-line no-undef
          crypto.randomUUID()
        : // TODO: remove when everything is on node 16 and browser support is stable
          `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export default getId;

export function addIdToItem(item) {
    return {
        ...item,
        id: item.id ? item.id : getId(),
    };
}

export function addIdToCollectionItems(collection) {
    return collection.map(addIdToItem);
}

import { MARKETS } from '@clearscore-group/lib.config.i18n';
import { getSessionCookie } from '@clearscore-group/lib.helpers.cookies';
import type { Market } from '@clearscore-group/lib.config.i18n';

export const getMarketFromPath = (): string | undefined => {
    const path = window.location.pathname;

    // we check 2 patterns: /market_name and /market_name/
    // e.g. /gb and /za/
    return MARKETS.find((market: string) => !!path.match(new RegExp(`\\/[\b${market}\\b]{2}($|/)`, 'g')));
};

export const getMarketFromParams = (): string | undefined => {
    const searchParams = new URLSearchParams(window.location.search);
    const marketParam = searchParams.get('market');
    return MARKETS.find((market: string) => market === marketParam);
};

/* detecting the market from session cookie, url path and market param */
export const getMarket = (): Market | undefined => {
    const cookie = getSessionCookie();

    return cookie?.market || getMarketFromPath() || getMarketFromParams();
};

const isObject = (value: unknown) => value && typeof value === 'object' && value.constructor === Object;

type TMarketConfig<V = unknown> = Partial<Record<Market, V>>;
type TMarketConfigValue<T extends TMarketConfig> = T extends TMarketConfig ? T[keyof T] : never;

export const getMarketConfig = <T extends TMarketConfig>(marketConfig: T): TMarketConfigValue<T> => {
    if (!marketConfig || !isObject(marketConfig)) {
        throw new Error('A market config object must be provided');
    }
    const market = getMarket();
    const config = market ? marketConfig[market] : null;
    if (!config) {
        throw new Error(`Config for ${market} could not be resolved`);
    }
    return config as TMarketConfigValue<T>;
};

import * as requestStatuses from '@clearscore-group/lib.constants.request-statuses';
import { PROFILE_FETCH_SUCCESS } from '@clearscore-group/lib.constants.profile-action-types';

import {
    PROSPECT_GET,
    PROSPECT_GET_SUCCESS,
    PROSPECT_GET_ERROR,
    PROSPECT_REGISTER,
    PROSPECT_CREATE,
    PROSPECT_CREATE_SUCCESS,
    PROSPECT_CREATE_ERROR,
    PROSPECT_CLEAR,
} from '../prospect.types';

const initialState = {
    status: requestStatuses.INITIAL,
};

const domain = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case PROSPECT_REGISTER:
            return {
                ...state,
                ...payload,
            };
        case PROSPECT_GET:
        case PROSPECT_CREATE:
            return {
                ...state,
                status: requestStatuses.PENDING,
            };
        case PROSPECT_GET_SUCCESS:
        case PROSPECT_CREATE_SUCCESS:
            return {
                ...state,
                ...payload,
                status: requestStatuses.SUCCESS,
            };
        case PROSPECT_GET_ERROR:
        case PROSPECT_CREATE_ERROR:
            return {
                ...state,
                status: requestStatuses.ERROR,
            };
        case PROSPECT_CLEAR:
        case PROFILE_FETCH_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export default domain;

import { PROD_BE_API_URL } from './constants';

/* eslint-disable import/prefer-default-export */
export const getCanonical = ({ location, siteUrl }: { location: string; siteUrl: string }): string => {
    let pathname = location || '/';

    if (pathname.endsWith('/')) {
        pathname = pathname.substring(0, pathname.length - 1);
    }

    return `${siteUrl}${pathname}`;
};

export const getUrlWithHost = (url: string) => {
    const env = process.env.NODE_ENV;
    // we need to use absolute urls for production
    // locally we use relative urls and proxy them in gatsby-config.ts
    const host = env === 'production' ? PROD_BE_API_URL : '';

    return `${host}${url}`;
};

const blacklistedSearchParams = ['email', 'firstName', 'lastName', 'dob'];

export const getSanitisedUrl = () => {
    const { location } = window;
    const url = new URL(location.href);
    blacklistedSearchParams.forEach((p) => url.searchParams.delete(p));
    const search = !url.search && location.search ? '?' : url.search;
    const urlString = `${url.protocol}//${url.host}${url.pathname}${search}`;

    return { url: urlString, search };
};

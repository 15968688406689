import { isValidLocale } from '@clearscore-group/lib.helpers.i18n';
import { MARKETS } from '@clearscore-group/lib.config.i18n';

interface CustomDetectorOptions {
    name: string;
    lookup: () => undefined | string;
}

const marketPath: CustomDetectorOptions = {
    name: 'marketPath',
    lookup: () => {
        if (typeof window !== 'undefined') {
            const regExp = new RegExp(
                [
                    ...MARKETS.map((market: string) => `/${market}/`),
                    ...MARKETS.map((market: string) => `/${market}$`),
                ].join('|'),
            );
            const result = regExp.exec(window.location.pathname)?.[0] ?? [];
            const possibleMarket = `en-${result[1]}${result[2]}` ?? '';
            return isValidLocale(possibleMarket) ? possibleMarket : undefined;
        }
        return undefined;
    },
};

export default marketPath;

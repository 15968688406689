import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';

const CACHE_NAME = '__cs__';
const LOCAL_NAMESPACE = 'local';
export const RESET_MARKET_ENVS = {
    local: {},
};

/**
 * Returns an env after providing a object path
 * @param path
 * @return {*}
 */

function getSingleEnv(path) {
    if (!canUseDom()) return ''; // @todo: [PM] get envs on the server
    let envs;

    if (window[CACHE_NAME] && window[CACHE_NAME].env) {
        envs = window[CACHE_NAME].env;
    } else {
        envs = window.env;
    }

    return resolveProp(`${LOCAL_NAMESPACE}.${path}`, envs) || resolveProp(path, envs);
}

export const getEnvs = () => {
    if (!canUseDom()) return '';
    return window[CACHE_NAME].env || window.env;
};

/**
 * Set envs
 * @param envs - new market envs
 * @return void
 */

export const setEnvs = (envs = {}) => {
    if (window[CACHE_NAME] && window[CACHE_NAME].env) {
        window[CACHE_NAME].env = Object.assign(window[CACHE_NAME].env, envs);
    } else {
        window.env = Object.assign(window.env, envs);
    }
};

/**
 * Returns an env after providing a object path or array of multiple object paths
 * @param paths
 * @return {*}
 */

export default function getEnv(paths) {
    if (Array.isArray(paths)) {
        return paths.map((path) => getSingleEnv(path));
    }
    return getSingleEnv(paths);
}

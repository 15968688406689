import getEnv from '@clearscore-group/lib.helpers.envs';

export const CS_SESSION_COOKIE = getEnv('SESSION_COOKIE') || 'CS_SESSION';
export const CS_PERSON_COOKIE = 'CS_PERSON';
export const CS_PUBLISHER_ID = 'CS_PUBLISHER_ID';
export const VISITED_SITE_COOKIE_NAME = 'CS_VISITED_SITE';
// Please note this is concatenated with `alertId` to give full cookie key
export const CS_ALERT_VIEWED_COOKIE_PREFIX = 'CS_ALERT_VIEWED';
export const CS_CURRENT_LANGUAGE = 'CS_CURRENT_LANGUAGE';
export const CS_ANONYMOUS_ID_COOKIE = 'CS_ANONYMOUS_ID';

// Expiry (in milliseconds)
export const EXPIRE_3DAYS = 259200000;

import { CLEAR_CONFIG, SET_CONFIG } from './config.types';

const initialState = {};

// eslint-disable-next-line import/prefer-default-export
export const configReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CONFIG:
            return {
                ...state,
                ...payload,
            };

        case CLEAR_CONFIG:
            return {};

        default:
            return state;
    }
};

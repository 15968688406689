import hasClientData from '@clearscore-group/lib.helpers.has-client-data';
import createAxiosClients from '@clearscore-group/lib.helpers.create-axios-clients';

import clientDirectory from './clients';
import getAuth from './auth';

const getClient = createAxiosClients(clientDirectory);

/**
 * Add client instance to payload meta object
 * @param {object} store redux store
 * @param {object} action redux action
 * @returns {boolean} if action structure is correct
 */
function addClient(store, action) {
    const {
        payload: {
            request: { client: clientKey, meta: requestMeta, headers },
        },
    } = action;

    const state = store.getState();
    const client = getClient(clientKey, state, requestMeta);

    return {
        ...action,
        payload: {
            ...action.payload,
            meta: {
                ...action.payload.meta,
                client,
            },
            request: {
                ...action.payload.request,
                headers: {
                    ...headers,
                    ...getAuth(clientKey, state),
                },
            },
        },
    };
}

/**
 * If the action payload has a client object, add the correct client instance.
 * If no client matches, fallback to the default client
 * @param {object} store redux store
 * @param {object} action redux action
 * @returns {object} action redux action
 */

function addClientInstance(store, action) {
    return hasClientData(action) ? addClient(store, action) : action;
}

export const allClients = getClient;
export default addClientInstance;

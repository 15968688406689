import { createSelector } from 'reselect';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getMarketingNewsEmailSubscribed = (state) => resolveProp('profile.mktg.newsEmailSubscribed', state);
const getMarketingOffersEmailSubscribed = (state) => resolveProp('profile.mktg.offersEmailSubscribed', state);
const getMarketingTipsEmailSubscribed = (state) => resolveProp('profile.mktg.tipsEmailSubscribed', state);
const getMarketingInfoEmailSubscribed = (state) => resolveProp('profile.mktg.infoEmailSubscribed', state);
const getMarketingPreferencesVersion = (state) => resolveProp('profile.data.mktgPreferencesVersion', state);
const getMarketingRtcEmailSubscribed = (state) => resolveProp('profile.mktg.rtcEmailSubscribed', state);
const getMarketingCoachEmailSubscribed = (state) => resolveProp('profile.mktg.coachEmailSubscribed', state);
const getMarketingEmailSubscribed = (state) => resolveProp('profile.mktg.emailSubscribed', state);

export default createSelector(
    getMarketingNewsEmailSubscribed,
    getMarketingOffersEmailSubscribed,
    getMarketingTipsEmailSubscribed,
    getMarketingInfoEmailSubscribed,
    getMarketingPreferencesVersion,
    getMarketingRtcEmailSubscribed,
    getMarketingCoachEmailSubscribed,
    getMarketingEmailSubscribed,
    (
        newsEmailSubscribed,
        offersEmailSubscribed,
        tipsEmailSubscribed,
        infoEmailSubscribed,
        mktgPreferencesVersion,
        rtcEmailSubscribed,
        coachEmailSubscribed,
        emailSubscribed,
    ) => {
        const preferences = {
            newsEmailSubscribed,
            offersEmailSubscribed,
            tipsEmailSubscribed,
            infoEmailSubscribed,
            mktgPreferencesVersion,
            rtcEmailSubscribed,
            coachEmailSubscribed,
            emailSubscribed,
        };

        return preferences;
    },
);

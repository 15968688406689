import { useCallback } from 'react';
import monitoring from '@clearscore-group/lib.helpers.monitoring';
import getEnv from '@clearscore-group/lib.helpers.envs';
import { selectors as profileSelectors } from '@clearscore-group/lib.redux.profile';
import { getSessionToken } from '@clearscore-group/lib.helpers.session';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { getSanitisedUrl } from './lib/helpers';
import useDefaultSegmentProps from './lib/hooks/use-default-segment-props';
import { sendToTrackingEventReceiver } from './lib/tracking-event-receiver';

/**
 * Get the default segment props that must be generated at event send time
 */
const getWindowSegmentProps = () => {
    const { url } = getSanitisedUrl();

    return {
        location: url,
        referrer: document.referrer || 'Direct',
    };
};

export const TRACKING_EVENT_RECEIVER_ENABLED = 'TRACKING_EVENT_RECEIVER_ENABLED';

const getUUIDFromSession = () => {
    try {
        return jwtDecode(getSessionToken())?.sub;
    } catch (e) {
        return null;
    }
};

const useTracking = () => {
    const defaultSegmentProps = useDefaultSegmentProps();
    const { uuid: profileUuid } = useSelector(profileSelectors.getSimple);
    const uuid = !profileUuid ? getUUIDFromSession() : profileUuid;

    const track = useCallback(
        ({ name, props = {}, event = 'track', onComplete, app } = {}) => {
            const hasTracking = typeof window?.analytics?.track === 'function';

            if (!hasTracking && monitoring.hasMonitoring()) {
                monitoring.log(`Cannot send tracking event ${name} - no window.analytics available`);
            }

            if (
                getEnv(TRACKING_EVENT_RECEIVER_ENABLED) === 'true' ||
                getEnv(TRACKING_EVENT_RECEIVER_ENABLED) === true
            ) {
                sendToTrackingEventReceiver({
                    name,
                    event,
                    uuid: uuid || null,
                    props: {
                        ...getWindowSegmentProps(),
                        ...defaultSegmentProps,
                        ...props,
                    },
                    app,
                });
            }

            if (!hasTracking) {
                return;
            }

            let hasFiredOnComplete = false;

            const fallbackTimeout = setTimeout(() => {
                if (onComplete && !hasFiredOnComplete) onComplete();
            }, 100);

            const customOnComplete = () => {
                hasFiredOnComplete = true;
                clearTimeout(fallbackTimeout);
                onComplete();
            };

            window.analytics[event](
                name,
                {
                    ...getWindowSegmentProps(),
                    ...defaultSegmentProps,
                    ...props,
                },
                // analytics.js "options" object - not needed for us (yet!)
                {},
                // An optional function called when the analytics request completes
                onComplete ? customOnComplete : undefined,
            );
        },
        [defaultSegmentProps],
    );

    return track;
};

export default useTracking;

import { GLOBAL, EN_GB } from '@clearscore-group/lib.config.i18n';
import { getSessionCookie } from '@clearscore-group/lib.helpers.cookies';
import { resolveProp } from '@clearscore-group/lib.helpers.resolve-props';

const getCurrentMarketDetails = () => {
    const sessionToken = getSessionCookie();
    const marketSessionToken = resolveProp('market', sessionToken);
    const localeSessionToken = resolveProp('locale', sessionToken);

    return {
        marketSessionToken,
        localeSessionToken,
    };
};

export const getActiveMarket = () => {
    const { marketSessionToken } = getCurrentMarketDetails();
    return marketSessionToken || GLOBAL;
};

export const getActiveLocale = () => {
    const { marketSessionToken, localeSessionToken } = getCurrentMarketDetails();

    if (localeSessionToken) return localeSessionToken;
    if (marketSessionToken) return `en-${marketSessionToken}`;
    return EN_GB;
};

import cookie from 'cookie';
import getEnvs from '@clearscore-group/lib.helpers.envs';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';

const hasDocumentCookie = () => canUseDom() && 'cookie' in window.document;

// if it looks like JSON, we'll treat it like JSON
const isParsingCookie = (value = '') => value[0] === '{' || value[0] === '[' || value[0] === '"';
const readCookie = (value) => {
    if (isParsingCookie(value)) {
        try {
            return JSON.parse(value);
        } catch (e) {
            // at least we tried
        }
    }
    return value;
};

const getDefaultOptions = () => ({
    ...(getEnvs('COOKIE_DOMAIN') && { domain: getEnvs('COOKIE_DOMAIN') }),
    path: '/',
    secure: true,
});

/**
 * set cookie
 * @param {string} cookieName
 * @param {any} payload
 * @param {object} options
 */
export const setCookie = (cookieName, payload, options) => {
    const cookieValue = typeof payload === 'object' ? JSON.stringify(payload) : payload;

    if (hasDocumentCookie()) {
        document.cookie = cookie.serialize(cookieName, cookieValue, {
            ...getDefaultOptions(),
            ...options,
        });
    }
};

/**
 * get cookie
 * @param {string} cookieName
 */
export const getCookie = (cookieName) => {
    if (hasDocumentCookie()) {
        const cookieValue = cookie.parse(document.cookie ?? '')[cookieName];
        return readCookie(cookieValue);
    }
    return null;
};

/**
 * get all cookies
 */
export const getAllCookies = () => {
    if (hasDocumentCookie()) {
        const allCookies = cookie.parse(document.cookie ?? '');

        return Object.entries(allCookies).reduce(
            (acc, [cookieKey, cookieValue]) => ({
                ...acc,
                [cookieKey]: readCookie(cookieValue),
            }),
            {},
        );
    }
    return null;
};

/**
 * deletes cookie
 * @param {string} cookieName
 */
export const removeCookie = (cookieName) => {
    if (hasDocumentCookie()) {
        const options = {
            ...getDefaultOptions(),
            expires: new Date(1970, 1, 1, 0, 0, 1),
            maxAge: 0,
        };
        document.cookie = cookie.serialize(cookieName, '', options);
    }
};

/* eslint-disable camelcase */
import { setCookie, getCookie, removeCookie } from './cookies';
import * as constants from './constants';

// needed for the remember-me checkbox on login
// work around since you can not use JS APIs to calculate if a cookie has 'expires' set
// We create a session with 'expires', then when changing language, or refreshing a token, we update the cookie.
// except, 'updating' cookies is impossible, so we need to cache the unreachable properties of the cookie
const CS_SESSION_EXPIRES = 'CS_SESSION_EXPIRES';
const setExpires = (expiresDate) => {
    window[CS_SESSION_EXPIRES] = expiresDate;
};
const getExpires = () => window[CS_SESSION_EXPIRES];

/**
 * Sets the session cookie
 * @param session.sessionToken
 * @param session.accountId
 * @param session.username
 * @param session.market
 * @todo SESSION - remove old auth flow when LLT/2FA is live
 */
export const setSessionCookie = ({ market, access_token, refresh_token, session_id, remember, o_token, locale }) => {
    const expiresDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setExpires(remember ? expiresDate : undefined);
    setCookie(
        constants.CS_SESSION_COOKIE,
        {
            access_token,
            refresh_token,
            session_id,
            o_token,
            market: market.toLowerCase(),
            ...(locale ? { locale } : {}),
        },
        {
            ...(remember && {
                expires: getExpires(),
            }),
        },
    );
};
/* eslint-enable camelcase */

/**
 * Update the session cookie
 */
export const updateSessionCookie = (data) => {
    const cookie = getCookie(constants.CS_SESSION_COOKIE);
    setSessionCookie({
        ...cookie,
        ...data,
        remember: !!getExpires(),
    });
};

/**
 * Get the session cookie
 */
export const getSessionCookie = () => getCookie(constants.CS_SESSION_COOKIE);

/**
 * Removes the session cookie
 */
export const removeSessionCookie = () => {
    removeCookie(constants.CS_SESSION_COOKIE);
};

export default setSessionCookie;

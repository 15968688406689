import axios from 'axios';
import interpolateWithMarket from '@clearscore-group/lib.helpers.interpolate-with-market';

const UNKNOWN_CLIENT_ERROR = (client) =>
    `ERROR [add-client-instance]: Unknown client '${client}'.
     Please check allowed clients in @clearscore-group/lib.helpers.add-client-instance`;

export default (clientDirectory) => {
    const clients = Object.keys(clientDirectory).reduce(
        (prev, clientName) => ({
            ...prev,
            [clientName]: axios.create(clientDirectory[clientName]),
        }),
        {},
    );

    return (clientKey, state, requestMeta) => {
        const client = clientDirectory[clientKey];
        const clientConfiguration = clients[clientKey];

        if (!client || !clientConfiguration) throw Error(UNKNOWN_CLIENT_ERROR(clientKey));

        const isFunction = (config) => typeof config === 'function';
        const baseURL = isFunction(client.baseURL) ? client.baseURL(state) : client.baseURL;

        const interpolatedURL = baseURL
            ? interpolateWithMarket({
                  state,
                  url: baseURL,
                  market: requestMeta ? requestMeta.market : null,
              })
            : null;

        clientConfiguration.defaults.baseURL = interpolatedURL;
        return clientConfiguration;
    };
};

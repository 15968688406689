import { getCookie, setCookie, removeCookie } from './cookies';
import { CS_PERSON_COOKIE } from './constants';

const second = 1000;
const minute = second * 60;
const thirtyMinutes = minute * 30;

export const setPersonCookie = (personIdentifier) => {
    setCookie(CS_PERSON_COOKIE, personIdentifier, {
        expires: new Date(new Date().getTime() + thirtyMinutes),
    });
};

export const getPersonCookie = () => getCookie(CS_PERSON_COOKIE);

export const removePersonCookie = () => removeCookie(CS_PERSON_COOKIE);

import getDevice from '@clearscore-group/lib.helpers.device';
import { getSessionToken } from '@clearscore-group/lib.helpers.session';

function addDeviceHeader() {
    return {
        'X-CS-Device': getDevice(),
    };
}
/**
 * Get auth headers required for Caesium API requests using bearer token
 * @returns {object} auth headers
 */
function getCaesiumBearerAuth() {
    return {
        Authorization: `Bearer ${getSessionToken()}`,
        ...addDeviceHeader(),
    };
}

/**
 * Get auth headers required for Caesium API request
 * @param {string} client api client to match auth headers for
 * @param {object} state from the redux store
 * @returns {object} auth headers
 */
// eslint-disable-next-line no-unused-vars
function getAuth(client, state) {
    switch (client) {
        case 'international':
            return getCaesiumBearerAuth();
        default:
            return {};
    }
}

export default getAuth;

export default function createRequestPayload(
    client,
    url,
    // eslint-disable-next-line default-param-last
    method = 'get',
    data,
    meta,
    headers,
    timeout,
    { params } = {},
) {
    return {
        request: {
            client,
            url,
            method,
            ...(data && { data }),
            ...(meta && { meta }),
            ...(headers && { headers }),
            ...(timeout && { timeout }),
            ...(params && { params }),
        },
    };
}

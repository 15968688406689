import createAction from '@clearscore-group/lib.helpers.create-action';
import createRequestPayload from '@clearscore-group/lib.helpers.create-request-payload';

import marketConfig from './lib/market-config';
import { PROSPECT_GET, PROSPECT_CREATE, PROSPECT_REGISTER, PROSPECT_CLEAR } from './prospect.types';

// eslint-disable-next-line import/prefer-default-export
export const createProspect = (data, market, headers) => {
    const { url } = marketConfig(market);
    return createAction(PROSPECT_CREATE, createRequestPayload('international', url, 'post', data, { market }, headers));
};

export const getProspect = (prospectId, market, headers) => {
    const { url } = marketConfig(market);
    return createAction(
        PROSPECT_GET,
        createRequestPayload('international', `${url}/${prospectId}`, 'get', null, { market }, headers),
    );
};

export const register = (data) => createAction(PROSPECT_REGISTER, data);

export const clearProspect = () => createAction(PROSPECT_CLEAR);

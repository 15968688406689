import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from '@clearscore-group/lib.hooks.use-previous';

import useTracking from '../use-tracking';
import { getSanitisedUrl } from '../lib/helpers';

const usePageTracking = ({ hasPageTracking, location: setLocation, props } = {}) => {
    const track = useTracking();
    const reactRouterLocation = useLocation();
    const location = setLocation || reactRouterLocation;
    const referrer = usePrevious(location.pathname);

    const { source, props: stateProps = {} } = location?.state?.tracking || {};

    useEffect(() => {
        if (hasPageTracking) {
            track({
                event: 'page',
                props: {
                    ...getSanitisedUrl(),
                    ...stateProps,
                    ...props,
                    referrer,
                    source,
                },
            });
        }
    }, [location?.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default usePageTracking;

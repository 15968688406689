import { createSelector } from 'reselect';

import legacyMap from './legacyMap';

const localeSelector = (state = {}) => state.locale;
const getActiveLanguage = (locale = {}) => ((locale && locale.languages) || []).find((copy) => copy.active === true);

export default createSelector(localeSelector, (locale) => {
    const activeLanguage = getActiveLanguage(locale) || {};
    const activeLocale = activeLanguage.languageCode || activeLanguage.code;
    return legacyMap[activeLocale] || activeLocale || 'en-gb';
});

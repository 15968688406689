const PROSPECT = 'CS/PROSPECT';

export const PROSPECT_GET = `${PROSPECT}_GET`;
export const PROSPECT_GET_SUCCESS = `${PROSPECT}_GET_SUCCESS`;
export const PROSPECT_GET_ERROR = `${PROSPECT}_GET_ERROR`;

export const PROSPECT_REGISTER = `${PROSPECT}_REGISTER`;

export const PROSPECT_CREATE = `${PROSPECT}_CREATE`;
export const PROSPECT_CREATE_SUCCESS = `${PROSPECT}_CREATE_SUCCESS`;
export const PROSPECT_CREATE_ERROR = `${PROSPECT}_CREATE_ERROR`;

export const PROSPECT_CLEAR = `${PROSPECT}_CLEAR`;
